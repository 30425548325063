#showroom{
  .link-show-item{
    text-decoration:none;
    color:inherit;
    .item-name{
      font-size:20px;
    }
    .item-pricediscount{
      color:$red;
      font-size:18px;
     }
    .item-discount-price{
      text-decoration:line-through;
      font-size:14px;
    }
    .item-price{
      font-size:18px;
    }
    .poster{
      object-fit: cover;
      vertical-align: bottom;
      height:450px;
      width:449px;
    }
  }
}

#showitem{
    #back{
      color:black;
      i{
        color:darkred;
      }
    }
  #showitemdetails{
    .item-pricediscount{
      color:$blue;
      font-weight:bold;
      font-size:35px;
      margin-right:20px;
    }
    .item-discount-price{
      font-size:20px;
      text-decoration:line-through;
    }
    .item-price{
      font-size:35px;
      color:$red;
      font-weight:bold;
    }
    #selectsize{
      border:1px solid black;
    }
  }
  .dropdown-item{
    &.disable{
      color: currentColor;
      cursor: not-allowed;
      opacity: 0.5;
      text-decoration: none;
    }
  }
  .poster-mini{
    width:150px;
    height:122px;
    object-fit: cover;
  }
  #portrait-img{
    object-fit: scale-down;
    width:100%;
    height:463px;
  }
  .swiper-img{
    height: 300px;
    width: 100%!important;
    object-fit: scale-down;
  }
}
#showitembasket{
  .delete-item{
    color:grey;
  }
}