@media screen and (max-width: 426px) {
  #page-bookgold{
    header#main-header-page-book{
      img{
        width: 100%!important;
      }
      h1{
        text-align: center;
      }
    }
  }
}

#page-testimony{

}

#page-bookgold{
  header#main-header-page-book{
    background: rgb(253,203,127);
    background: linear-gradient(159deg, rgba(253,203,127,1) 0%, rgba(244,56,63,1) 82%);
    padding: 25px;
    h1{
      color:white;
    }
  }

  .book-text{
    background-color:#F0F0F0;
    position: relative;
    header{
      h2{
        color:#444141;
      }
      span{
        font-style: italic;
        font-size:12px
      }
    }
    div{
      color:#444141;
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      top: 30px;
      left: -30px;
      width: 30px;
      height: 30px;
      border: 15px solid transparent;
      border-right: 15px solid #F0F0F0;
    }

  }
}