.generic-header{

  &.result-header{
    background: url('~/public/img/result/bg-result.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 70px;
    h1{
      color: white;
      font-family:'Montserrat-Bold';
      text-transform: uppercase;
    }
  }
}

.result-body{
    padding-bottom: 74px;
    padding-top: 40px;
    .result-header-body{
      //background: url('~/public/img/result/capucine-fitnessavoda-result.png');
      background-repeat: no-repeat;
      overflow: hidden;
      min-height: 353px;
    .infos-result{
      background-color: #8e8c8c;
      color: white;
      font-family: Roboto;}
    }


  }

.list-portrait{

  margin-bottom: 15px;
  li{
    margin-bottom: 25px;
    a{
      &:hover{
        cursor: pointer;
        text-decoration: none;
        color: inherit;
        .container-img{
          transform: translate(0px, 20px);
        }
      }
      background-color: $grey;
      position: relative;
      overflow: hidden;
      .container-img{
        position: absolute;
        top: 18px;
        left: 23px;
        transition: transform 1s;
        z-index:2;
        height: 148px;
        overflow: hidden;
      }
      div.grid{
        height: 151px;
        width: 178px;
        transition: transform 1s;
        background-image:url("~/public/img/result/grid-portrait-result.png");
        background-repeat: no-repeat;
        margin-bottom:20px;
      }
      .firstname{
        text-transform:capitalize;
        color:black;
        margin-right:25px;
        margin-left:25px;
      }
      .lostweight{
        color:black;
        font-weight:bold;
        //font-size:24px;
      }


    }

  }

}
.hidden-content{

  article{
    i{
      margin-right:5px;
      font-size:12px;
    }
    .bad-weight{
      color:$red;
    }
    .good-weight{
      color:green;
    }
  }
  h2{
    color:$red;
    font-weight:bold;
  }
  h4{
    color:black;
    font-size:16px;
    font-weight:bold;
  }
  display:none;
  aside{
    img{
      margin-bottom:15px;
    }


.lostweight{
  color:$red;
  display:block;
  text-align:center;
}
  }
}