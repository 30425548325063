.simulator-poping{
  padding: 20px;
  position: relative;
  .logo{
    margin-bottom: 25px;

  }
  .commun-section{
    h4{
      display: block;
      width:100%;

      font-family: Bebas;
      font-size: 28px;
      line-height: 20px;
    }
  }
  .fitname{
    i{
      color:black;
      font-size:40px;
    }

    font-size:20px;
    font-weight:bold;
  }

  .banner-offer{
    h4{
      margin-bottom:50px;
    }
    .list-offer{
      display: block;
      width:100%;
      margin-bottom: 40px;
      li{

        .container-offers{
          cursor: pointer;
          &.selected{
            border:3px $red dashed;

          }
          border:3px transparent dashed;
          background-clip: padding-box;
          border-radius:15%;
          height: 166px;
          width: 166px;
          padding: 5px;

          &#first-offer{
            background-image: url('~/public/img/offers/new-offer-166x166.png');

          }

          &#second-offer{
            background-image: url("~/public/img/offers/new-offer2-166x166.png");
          }
          h2{
            color:white;
            text-transform: uppercase;
            font-size: 20px;
          }
          .price{
            text-align: center;
            .real-price{
              color: $red;
              font-size: 25px;
              font-weight: bold;
              display: block;
            }
            .promo{
              display: block;
              color: white;
            }
          }

        }

      }
    }
  }

  .banner-person{
    display: none;
    header{
      margin-bottom:50px;
      h4{
        margin-bottom:20px;
      }
      div{
        display: block;
        width: 100%;
        text-align: center;
        i{
          font-size: 20px;
          margin-right: 10px;
        }
      }
    }
    .row-user{
      display:block;
      width:100%;
      margin-bottom: 15px;

      label.error{
        color: $red;
        font-family: Roboto-Bold;
        &+label{

        }
      }

      input[type=text]{
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      }
      .woman-body{
        display:none;
      }

      .list-choice-body{
        li{
          div{
            border:2px solid transparent;
            padding:5px;
            cursor: pointer;
            span{
              display:block;
              text-align:center;
            }
            i{
              font-size:16px;
            }
          }
          &.selected{
            div{
              border:2px dashed $blue;
              background-clip: padding-box;
              border-radius:15%;
              i{
                color:$blue;
              }
            }
          }
        }
      }
      .infos-user{
        display:block;
        font-size:15px;
        color:$grey;
        text-align: center;
      }
    }
    label.user-label{
      font-family: Roboto-Bold;
      &.block{
        display: block;
      }

    }
  }
  button{
    padding-right: 5.5em;
    padding-left: 5.5em;
    display: block;
  }
}