.page-validation{
  padding-top: 25px;
  padding-bottom: 25px;
  .header{
    .congratulation{
      color:$red;
      font-size:40px;
    }
    font-weight:bold;
    font-size:30px;

  }
  input[type=text]{
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
.indication{
  text-decoration:underline;
}
  .link{
    color:black;
    text-decoration:underline;
  }
  form{
    a{
      color: black;
      &:hover{
        text-decoration: none;
      }

      i{

      }
    }

  }
}