.creditcard{
  width:500px;
  background: url('~/public/img/recap/ccBackBg.png') 0 0 repeat-x;
  border-radius: 15px;
  height: 260px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(119, 119, 119, 0.5);
  box-shadow: 0 2px 5px 0 rgba(119, 119, 119, 0.5);
  position: relative;
  overflow: hidden;
  &:after{
    content: '';
    display: block;
    height: 100%;
    width: 70px;
    top: 0;
    right: 106px;
    position: absolute;
    background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.33) 1%, rgba(0, 0, 0, 0.33) 20%, rgba(0, 0, 0, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.33) 1%, rgba(0, 0, 0, 0.33) 20%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.33) 1%, rgba(0, 0, 0, 0.33) 20%, rgba(0, 0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#54000000', endColorstr='#00000000', GradientType=1);

  }

  .ccfront{
    height:260px;
    width:354px;
    background: url('~/public/img/recap/ccFrontBg.png') 0 0 repeat-x;
    float:left;
    border-radius: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #ffffff;
    z-index:1;
    position:relative;
    padding:15px;

  }
  #cvc{
    position: absolute;
    right: 23px;
    top: 92px;
    width: 61px;
  }
  .row-card{
    padding:10px;
    background-color:white;
    margin-bottom:15px;
  }
}