* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
  #page-listevent{
    .btn:last-child {
      margin-right: 0;
    }
    /*.btn:hover, .btn.js-active {
      color: #fff;
      background-color: #2c2c2c;
    }*/

    .header {
      max-width: 500px;
      margin: 50px auto;
      text-align: center;
    }

    .header__title {
      margin-bottom: 30px;
      font-weight: 600;
    }

    .content {
      max-width: 800px;
      width: 95%;
      margin: 0 auto 40px;
    }

    .content__title {
      margin-bottom: 40px;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .timeline {
      position: relative;
      min-height: 500px;
      width: 100%;
      padding: 60px 0;
    }

    .timeline__bar {
      position: absolute;
      top: 0;
      left: 50%;
      width: 6px;
      height: 100%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      background-image: linear-gradient($grey, black);
    }
    .timeline__bar:before, .timeline__bar:after {
      position: absolute;
      left: 50%;
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      border-radius: 50%;
    }
    .timeline__bar:before {
      top: -1px;
      background-color: $grey;
    }
    .timeline__bar:after {
      bottom: -1px;
      background-color: black;
    }

    .timeline__elem {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }
    .timeline__elem:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 900px) {
      .timeline__elem {
        flex-direction: row;
      }
    }

    @media (min-width: 900px) {
      .timeline__elem--right {
        justify-content: flex-end;
      }
    }

    .timeline__date {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      margin: auto;
      color: $red;
      background-repeat: no-repeat;
      border-radius: 50%;
      border: 6px solid $red;
      background-color:white;
    }
    @media (min-width: 900px) {
      .timeline__date {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
    }

    .timeline__date-day {
      font-size: 1.35rem;
      font-weight: 600;
    }

    .timeline__date-month {
      font-size: .85rem;
      font-weight: 600;
    }

    .timeline__event {
      position: relative;
      width: 90%;
      margin: 30px auto 0;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
      background-color: #fff;
    }
    @media (min-width: 560px) {
      .timeline__event {
        width: 65%;
      }
    }
    @media (min-width: 900px) {
      .timeline__event {
        width: calc((100% - 70px - 8px * 4) / 2);
        margin: 0;
      }
    }
    .timeline__event:before {
      position: absolute;
      top: 50%;
      display: none;
      width: 0;
      height: 0;
      content: '';
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
    }
    .timeline__event:after {
      position: absolute;
      top: 50%;
      display: none;
      width: 16px;
      height: 16px;
      content: '';
      -webkit-transform: translateY(-50%) rotate(45deg);
      transform: translateY(-50%) rotate(45deg);
    }
    @media (min-width: 900px) {
      .timeline__event:before, .timeline__event:after {
        display: block;
      }
    }

    .timeline__event--left:before {
      right: -8px;
      border-left: 8px solid #fff;
    }
    .timeline__event--left:after {
      right: -7px;
      box-shadow: 2px -1px 1px 0 rgba(0, 0, 0, 0.08);
    }

    .timeline__event--right:before {
      left: -8px;
      border-right: 8px solid #fff;
    }
    .timeline__event--right:after {
      left: -7px;
      box-shadow: -2px 2px 1px 0 rgba(0, 0, 0, 0.08);
    }

    .timeline__event-date-time {
      padding: 20px 15px 0;
      display: flex;
      margin-bottom: 15px;
      font-size: .8rem;
      color: rgba(44, 44, 44, 0.7);
    }

    .timeline__event-date {
      margin-right: 10px;
    }

    .timeline__event-title {
      padding: 0 15px;
      margin-bottom: 15px;
      font-size: 1.25rem;
      font-weight: 600;
    }

    .timeline__event-descr {
      padding: 0 15px 20px;
      font-size: .9rem;
      line-height: 1rem;
      .badge-price{
        font-size:18px;
        color:$blue;
        font-weight:bold;
        display:block;
      }
      a{
        color:black;
      }
    }

    .timeline__event-actions {
      padding: 20px 15px;
      border-top: 1px solid rgba(160, 160, 160, 0.2);
    }

    .timeline__event-action {
      transition-property: all;
      transition-duration: 0.2s;
      transition-timing-function: linear;
      transition-delay: 0s;
      margin-right: 24px;
      font-size: .9rem;
      text-transform: uppercase;
    }
    .event__description{
      display:none;
    }

  }

