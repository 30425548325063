@charset "UTF-8";
/*=============================================
=             Colours                         =
=============================================*/
/************ layout ***********************/
@font-face {
  font-family: "Montserrat-Regular";
  src: url("~/public/assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat-Bold";
  src: url("~/public/assets/fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("~/public/assets/fonts/Roboto/Roboto-Bold.ttf");
}
@font-face {
  font-family: "KGNoRegretsSketch";
  src: url("~/public/assets/fonts/KGNoRegretsSketch/KGNoRegretsSketch.ttf");
}
@font-face {
  font-family: "SketchCollege";
  src: url("~/public/assets/fonts/SketchCollege/SketchCollege.ttf");
}
@font-face {
  font-family: "Bebas";
  src: url("~/public/assets/fonts/Bebas/BebasNeue-Regular.ttf");
}
.btn.call-action {
  background-color: #0683bf;
  color: white;
  font-family: Roboto-Bold;
}
.btn.call-action:hover {
  color: white;
  background-color: #045075;
}

.text-content-popover .line {
  display: block;
  margin-bottom: 5px;
}
.text-content-popover .line i {
  color: black;
  width: 25px;
  font-size: 16px;
}
.text-content-popover a {
  margin: auto;
  display: block;
  color: white;
  background-color: #4a4a4a;
  margin-top: 10px;
}

.content-popover {
  display: none;
}

.backgroundColor-white {
  background-color: white;
}

.backgroundColor-black {
  background-color: black;
}

.bg-home-color {
  background: rgb(46, 186, 204);
  background: linear-gradient(139deg, rgb(46, 186, 204) 0%, rgb(233, 159, 130) 55%, rgb(254, 209, 131) 100%);
}

.transition, .video-program::after, .why-us-home::after, .simulator-home::after, .simulator-home h1::after, .before-home::after {
  position: absolute;
  font-weight: bold;
  color: white;
  padding: 10px;
  margin-top: -38px;
  text-transform: uppercase;
  text-align: center;
  line-height: 60px;
  z-index: 1;
  border-bottom: 40px solid #4a4a4a;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  height: 0;
}

.no-padding-lr {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.no-margin-lr {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.roboto-bold {
  font-family: Roboto-Bold;
}

.clear {
  clear: both;
}

.generic-header {
  height: 350px;
  background-color: #4a4a4a;
}

.generic-container-body {
  background: linear-gradient(0deg, white 10%, white 11%);
  -webkit-transform: skew(0deg, 7.5deg);
  -moz-transform: skew(0deg, 7.5deg);
  transform: skew(0deg, 7.5deg) translate(0px, -96px);
  padding-top: 40px;
  height: auto;
  overflow: hidden;
}
.generic-container-body .generic-body {
  transform: skew(0deg, -7.5deg);
}

.overflow {
  overflow: hidden;
}

.height-auto {
  height: auto;
}

.blockvideo {
  position: relative;
  text-align: center;
}
.blockvideo:hover i {
  color: white;
}
.blockvideo i {
  position: absolute;
  color: #cc3333;
  bottom: 0;
  top: 0;
  margin: auto;
  left: 0;
  right: 0;
  font-size: 50px;
}

.bg-white-2 {
  background-color: #FDF8FB;
}

.text-color-pink {
  color: #BC1B81;
}

.text-color-purple {
  color: #5D3C81;
}

.btn-pink {
  background-color: #BC1B81;
  color: white;
  border-radius: 0 !important;
}

.btn-purple {
  background-color: #5D3C81;
  color: white;
  border-radius: 0 !important;
}
.btn-purple:hover {
  background-color: #442c5e;
  color: white;
}

.btn-yellow, #prog-show.fit-2 button.btn, #prog-show.fit-2 a.btn, #prog-show.bikini-express button.btn, #prog-show.bikini-express a.btn {
  background-color: #FED183;
  color: white;
  border-radius: 0 !important;
}
.btn-yellow:hover, #prog-show.fit-2 button.btn:hover, #prog-show.fit-2 a.btn:hover, #prog-show.bikini-express button.btn:hover, #prog-show.bikini-express a.btn:hover {
  background-color: #febe50;
  color: white;
}

.btn-brown, #prog-show.fit-1 button.btn, #prog-show.fit-1 a.btn {
  background-color: #E99F82;
  color: white;
  border-radius: 0 !important;
}
.btn-brown:hover, #prog-show.fit-1 button.btn:hover, #prog-show.fit-1 a.btn:hover {
  background-color: #e17e57;
  color: white;
}

.btn-blue, #prog-show.fit-hiver button.btn, #prog-show.fit-hiver a.btn {
  background-color: #03C1DE;
  color: white;
  border-radius: 0 !important;
}
.btn-blue:hover, #prog-show.fit-hiver button.btn:hover, #prog-show.fit-hiver a.btn:hover {
  background-color: #0295ac;
  color: white;
}

.list-social i {
  font-size: 29px;
  color: white;
  padding: 6px;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  width: 41px;
  margin: auto;
  margin-bottom: 15px;
}
.list-social i.fa-facebook-f {
  background-color: #4267B2;
}
.list-social i.fa-snapchat {
  background-color: #FFFC00;
}
.list-social i.fa-instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.family-bebas {
  font-family: Bebas;
  font-weight: 500 !important;
}

body {
  font-family: Montserrat-Regular;
}
body .logo-text {
  font-size: 25px;
  font-family: Bebas !important;
}
body .logo-text .fit {
  color: #F4323D;
}
body .logo-text .nessavoda {
  color: #BC1B81;
}
body .dropdown-toggle {
  font-size: 18px;
  margin-top: 7px;
}
body .dropdown-menu[data-bs-popper] {
  right: 0px;
}

@media screen and (min-width: 992px) {
  #main-header .navbar-expand-lg .navbar-collapse {
    display: block !important;
  }
}
@media screen and (max-width: 426px) {
  .navbar > .container {
    flex-direction: row-reverse !important;
  }
  .mobile-menu {
    display: flex !important;
  }
  .desktop-menu {
    display: none !important;
  }
}
.mobile-menu {
  display: none;
}

.desktop-menu {
  display: flex;
}

#main-header {
  color: #5D3C81;
}
#main-header .nav-link {
  color: #5D3C81;
}
#main-header .dropdown span, #main-header .dropdown a {
  color: #5D3C81;
}

.nav {
  background-color: black;
  color: white;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  padding-left: 15px;
}
.nav .dropdown-menu .dropdown-item.active {
  background-color: #cc3333 !important;
}
.nav .dropdown-menu .dropdown-item:hover {
  background-color: #cc3333 !important;
}
.nav .navbar-toggler {
  background-color: #cc3333 !important;
  color: white;
}
.nav ul li.nav-item:hover, .nav ul li.nav-item.active {
  background-color: #cc3333;
}
.nav ul li.nav-item a {
  padding: 10px;
  color: white !important;
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  font-size: 14px;
}
.nav .dropdown-menu {
  background-color: #4a4a4a !important;
  border: none !important;
}
.nav .dropdown-menu a {
  font-size: 15px !important;
  color: white;
  text-transform: capitalize !important;
}
.nav .dropdown-menu a + a {
  border-top: 1px solid white;
}
.nav .instagram {
  background-color: #cc3333;
  color: white !important;
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  font-size: 18px;
}

.dropdown-menu {
  border: none;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #E99F82;
}

footer {
  background-color: white;
  padding: 20px;
  color: black;
}
footer a {
  color: inherit;
}
footer a:hover {
  color: inherit;
}

#form-contact select {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#form-contact input[type=text], #form-contact textarea {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.app-msg {
  padding: 30px 41px 10px 32px !important;
}
.app-msg.danger .header-success {
  display: none;
}
.app-msg.warning {
  border: 2px solid #cc3333;
}
.app-msg.warning .alert-warning {
  background-color: white !important;
  color: #cc3333 !important;
}
.app-msg.warning .header-success {
  display: none;
}
.app-msg.success {
  border: 2px solid #155724;
}
.app-msg.success .header-success {
  color: #155724;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}
.app-msg.success .header-success i {
  margin-right: 10px;
  font-size: 25px;
  color: #155724;
}
.app-msg.success .alert-success {
  background-color: white !important;
}

@media screen and (max-width: 943px) {
  .bg-banner-fitgroup {
    height: 100% !important;
    background-image: url("~/public/img/home/banner-2.jpeg") !important;
    padding-bottom: 50px !important;
  }
}
@media screen and (max-width: 426px) {
  .section-home .poster-program {
    width: 100% !important;
  }
  .block-program {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .block-section-fitgroup span:nth-child(3) {
    font-size: 38px !important;
  }
  .section-social-network {
    position: relative;
  }
  .section-social-network .left-block {
    width: 100% !important;
  }
  .section-social-network .right-block {
    position: absolute;
    width: 100% !important;
    top: 40%;
  }
  .section-social-network .hide-mobile {
    display: none;
  }
  .section-social-network .show-mobile {
    display: block !important;
    font-size: 30px;
    margin-bottom: 10px;
  }
  .section-home #block-text {
    padding-top: 0 !important;
  }
  .section-home #block-text #text-2 {
    font-size: 20px !important;
    margin-bottom: -15px;
  }
}
.section-social-network .left-block, .section-social-network .right-block {
  width: 50%;
}
.section-social-network .left-block .show-mobile, .section-social-network .right-block .show-mobile {
  display: none;
}

.pd-home {
  padding-top: 40px;
  padding-bottom: 40px;
}

.section-home .poster-program {
  width: 30%;
}
.section-home #block-text {
  padding-top: 80px;
  padding-bottom: 40px;
}
.section-home #block-text h2 {
  font-size: 55px;
  font-weight: bold;
  margin-bottom: 10px;
}
.section-home #block-text #text-2 {
  font-size: 40px;
  font-weight: 700;
}
.section-home #block-text #text-3 {
  color: #5D3C81;
  font-size: 45px;
  font-weight: bold;
}
.section-home #block-text #text-3 span {
  font-size: 70px;
}
.section-home #block-text a {
  border: 2px solid #5D3C81;
  color: #5D3C81;
  border-radius: 0px;
  font-weight: 700;
  font-size: 22px;
}

h2.title {
  color: #5D3C81;
  font-weight: 500;
  margin-bottom: 45px;
  text-transform: uppercase;
  text-align: center;
  font-family: Bebas;
  font-size: 34px;
}

.bg-fit-program {
  background-size: 100%;
  background-repeat: no-repeat;
}

.bg-poster-program {
  height: 290px;
  width: 80%;
  margin: 0 auto;
}
.bg-poster-program .price {
  position: absolute;
  color: white;
  bottom: 5px;
  right: -19px;
  font-weight: 500;
  font-size: 50px;
  font-family: Bebas;
}

.block-program {
  width: 300px;
  border: 1px solid rgb(92, 61, 129);
  border-radius: 10px;
}
.block-program.first {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(3, 193, 222) 58%);
}
.block-program.first .bg-fit-program {
  background-image: url("~/public/img/home/fit2.png");
}
.block-program.first .text-block header {
  color: #03C1DE;
}
.block-program.first .text-block a {
  background-color: #03C1DE;
  color: white;
}
.block-program.second {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(245, 148, 87) 72%);
}
.block-program.second .bg-fit-program {
  background-image: url("~/public/img/home/fit2ete.png");
}
.block-program.second .text-block header {
  color: #E99F82;
}
.block-program.second .text-block a {
  background-color: #E99F82;
  color: white;
}
.block-program.third {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(254, 209, 130) 72%);
}
.block-program.third .bg-fit-program {
  background-image: url("~/public/img/home/bikini.png");
  background-position: center;
}
.block-program.third .bg-fit-program h2 {
  color: #f23833;
  font-size: 40px;
  padding-top: 40px;
}
.block-program.third .text-block header {
  color: #FED183;
}
.block-program.third .text-block a {
  background-color: #FED183;
  color: white;
}
.block-program .text-block {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 0px 9px 9px;
}
.block-program .text-block header {
  margin-bottom: 10px;
  font-weight: 500;
  font-family: Bebas;
}
.block-program .text-block header h4 {
  margin-bottom: 0px;
  font-size: 34px;
}
.block-program .text-block header div {
  font-size: 30px;
}
.block-program .text-block a {
  text-decoration: none;
  padding: 8px;
  margin-top: 20px;
  display: inline-block;
}

.concept-block {
  background-color: #FDF8FB;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.concept-block .line {
  text-align: center;
}
.concept-block .line i {
  display: block;
  color: #5D3C81;
  font-size: 25px;
  margin-bottom: 15px;
}

.bg-banner-fitgroup {
  background-image: url("~/public/img/home/banner.png");
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 83px;
}

.block-section-fitgroup span {
  font-weight: bold;
}
.block-section-fitgroup span:nth-child(1) {
  font-size: 45px;
  margin-bottom: -31px;
}
.block-section-fitgroup span:nth-child(2) {
  font-size: 65px;
}
.block-section-fitgroup span:nth-child(3) {
  font-size: 65px;
}
.block-section-fitgroup span:nth-child(4) {
  font-size: 65px;
}
.block-section-fitgroup span:nth-child(5) {
  font-size: 30px;
}

.banner-home {
  width: 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.banner-home .card-item {
  padding: 5px;
  margin-top: auto;
}
.banner-home .card-item li {
  padding: 5px;
}
.banner-home .card-item li a {
  display: block;
  background-color: white;
  text-align: center !important;
  padding: 15px;
  height: 100%;
}
.banner-home .card-item li a i {
  color: #0683bf;
  font-size: 30px;
}
.banner-home .card-item li a span {
  margin-top: 15px;
  display: block;
  text-transform: uppercase;
  font-family: Roboto-Bold;
  font-weight: bold;
  color: black !important;
}
.banner-home .card-item li a:hover {
  text-decoration: none;
}
.banner-home h2 {
  font-family: "SketchCollege";
  color: white;
  font-size: 4em;
}
.banner-home .accroche {
  font-family: "SketchCollege";
  color: white;
  font-size: 2em;
}

.home-h1 {
  padding-top: 45px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Montserrat-Bold";
  text-align: center;
  width: 100%;
  font-size: 28px;
}
.home-h1 span {
  color: #cc3333;
  font-size: 30px;
}

.background-simulator {
  width: 100%;
  padding-bottom: 50px;
  display: none;
}
.background-simulator .input-group-text {
  background-color: white;
  color: #5D3C81;
  font-weight: bold;
  border-color: #5D3C81 !important;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-left: 0;
}
.background-simulator input[type=text].form-control {
  border-color: #5D3C81 !important;
  border-left: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 0px;
}
.background-simulator input[type=submit] {
  background-color: white;
  color: #5D3C81;
  border: 1px solid #5D3C81;
}
.background-simulator input[type=submit]:hover {
  background-color: #5D3C81;
  color: white;
}
.background-simulator h2 {
  color: #BC1B83;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 32px;
}
.background-simulator .mesure {
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.background-simulator .call-simulator, .background-simulator .result-simulator {
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
.background-simulator input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #5D3C81;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s;
}

.before-home {
  background-color: #c9c9c9;
  width: 100%;
}
.before-home .carousel-indicators {
  position: inherit;
}
.before-home .carousel {
  background-color: black;
  margin-bottom: 40px;
}
.before-home .carousel img {
  /* margin: 0 auto;
   display: block;*/
}
.before-home .carousel ul li .desc-img .title-program, .before-home .carousel ul li .desc-img .name-heavy {
  display: block;
  font-family: Roboto-Bold;
  color: white;
}
.before-home .carousel ul li .desc-img .title-program {
  font-size: 18px;
}
.before-home .carousel ul li .desc-img .name-heavy {
  color: white;
}
.before-home .carousel ul li .desc-img .name-heavy span {
  color: #cc3333;
  font-size: 18px;
  margin-left: 15px;
}
.before-home .carousel a {
  color: white;
}
.before-home .carousel a i {
  color: white;
}
.before-home .user-text {
  padding-top: 80px;
  padding-bottom: 80px;
}
.before-home .user-text h2 {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
}
.before-home .user-text .p-describe {
  font-size: 18px;
}
.before-home .user-text .p-describe a {
  color: inherit;
}
.before-home .user-text p {
  font-family: Roboto;
}
.before-home .user-text p span {
  color: #cc3333;
  font-weight: bold;
}
.before-home .user-text .p-price {
  font-family: Roboto-Bold;
}
.before-home .user-text .p-price .label {
  display: inline-block;
  color: black;
  font-size: 25px;
  margin-right: 60px;
}
.before-home .user-text .p-price .price {
  font-size: 40px;
  display: inline-block;
}
.before-home .user-text .p-price .price span {
  display: block !important;
}
.before-home .user-text .p-price .price span.normal-price {
  color: black;
  text-decoration: line-through;
  font-size: 20px;
  text-align: right;
}
.before-home .user-text #notice-user {
  position: relative;
}
.before-home .user-text #notice-user::before {
  position: absolute;
  content: "";
  top: -64px;
  left: -70px;
  width: 124px;
  height: 128px;
  background: url("~/public/img/home/stars.png") center no-repeat;
  z-index: 1;
}
.before-home::after {
  /*content: "Sport à la maison";
  left: 0;
  right:0;
  width: 300px;
  margin: -38px auto;*/
  display: none;
}

.simulator-home h1::after {
  content: "Simulateur";
  left: 0;
  right: 0;
  width: 300px;
  margin: 81px auto;
  font-size: 14px;
}
.simulator-home::after {
  content: "Témoignages";
  left: 0;
  right: 0;
  width: 300px;
  margin: -38px auto;
}

.why-us-home::after {
  content: "Les FitGroups";
  left: 0;
  right: 0;
  width: 300px;
  margin: -38px auto;
}
.why-us-home .banner-video {
  min-height: 367px;
  background-image: url("~/public/img/home/presentation-video-3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin-bottom: 40px;
  text-align: center;
  padding-top: 135px;
}
.why-us-home .banner-video i {
  display: block;
  color: #cc3333;
  z-index: 2;
  font-size: 69px;
  margin-bottom: 50px;
  cursor: pointer;
}
.why-us-home .banner-video i:hover {
  color: white;
}
.why-us-home .banner-video span {
  display: block;
  color: white;
  font-size: 30px;
  font-weight: bold;
}
.why-us-home h3 {
  padding: 80px;
}

.presentation-video-exrcices {
  width: 100%;
  min-height: 388px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}
.presentation-video-exrcices .link {
  color: #cc3333;
  text-decoration: underline;
}
.presentation-video-exrcices .col-md-5 {
  display: flex;
  flex-direction: column; /* direction d'affichage verticale */
  justify-content: center;
}
.presentation-video-exrcices .text {
  background-color: rgba(0, 0, 0, 0.75);
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
  margin: auto;
}
.presentation-video-exrcices .text p {
  color: white;
  opacity: 1;
  font-family: Roboto-Bold;
}
.presentation-video-exrcices .text .fa-play-circle {
  font-size: 25px;
}
.presentation-video-exrcices .img-multidevices {
  display: block;
  margin: 0 auto;
}

.fitgroup-home {
  padding: 100px 10px 10px 10px;
  background-image: url("~/public/img/home/bg-fitgroup.png");
  background-repeat: no-repeat;
  background-position: bottom center;
}
.fitgroup-home h3 {
  text-align: center;
  font-family: Roboto;
}
.fitgroup-home h3 span {
  color: #cc3333;
  font-family: Roboto-Bold;
  font-size: 25px;
  text-transform: uppercase;
}
.fitgroup-home .link-register {
  margin: 0 auto;
  display: block;
  width: 209px;
}
.fitgroup-home h2 span {
  color: #cc3333;
  font-family: Roboto-Bold;
}
.fitgroup-home h2 .brands-fit {
  font-family: Montserrat-Bold;
  color: #cc3333;
}
.fitgroup-home h2 .brands-group-name {
  font-family: Montserrat-Bold;
  color: black;
}
.fitgroup-home .list-social li {
  padding: 5px;
}
.fitgroup-home .list-social li i {
  font-size: 30px;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 100%;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}
.fitgroup-home .list-social li i.fa-facebook-f {
  background-color: #4267B2;
}
.fitgroup-home .list-social li i.fa-snapchat {
  background-color: #FFFC00;
}
.fitgroup-home .list-social li i.fa-instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
}

.infos-program {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: black;
}
.infos-program li i {
  color: #0683bf;
  font-size: 25px;
  margin-bottom: 10px;
}
.infos-program li span {
  color: white;
}

.banner-program {
  width: 100%;
  min-height: 620px;
  background-repeat: no-repeat;
  background-image: url("~/public/img/program/bg-2.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 100px;
  padding-bottom: 30px;
}
.banner-program h1 {
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-family: Montserrat-Bold;
  font-size: 30px;
  margin-bottom: 30px;
}
.banner-program h1 span {
  color: #cc3333;
}
.banner-program h2 {
  text-align: center;
  font-family: Montserrat;
  color: white;
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 55px;
}
.banner-program ul {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
}
.banner-program ul li {
  text-align: center;
}
.banner-program ul li i, .banner-program ul li div {
  background-color: white;
  color: #0683bf;
  font-size: 35px;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border: 2px #0683bf dashed;
  margin-bottom: 10px;
}
.banner-program ul li i {
  line-height: 94px;
}
.banner-program ul li div {
  margin: 0 auto;
  padding: 15px;
}
.banner-program ul li div img {
  fill: red;
  filter: invert(39%) sepia(52%) saturate(1158%) hue-rotate(163deg) brightness(95%) contrast(102%);
  height: 65px;
}
.banner-program ul li span {
  color: white;
  display: block;
  font-family: Roboto;
  font-size: 18px;
}
.banner-program .call-action {
  margin: 0 auto;
  display: block;
  padding-left: 50px;
  padding-right: 50px;
}

ul.icones-ul {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
}
ul.icones-ul li {
  text-align: center;
}
ul.icones-ul li i, ul.icones-ul li div {
  background-color: white;
  color: #0683bf;
  font-size: 35px;
  height: 100px;
  width: 100px;
  border-radius: 100%;
  border: 2px #0683bf dashed;
  margin-bottom: 10px;
}
ul.icones-ul li i {
  line-height: 94px;
}
ul.icones-ul li div {
  margin: 0 auto;
  padding: 15px;
}
ul.icones-ul li div img {
  fill: red;
  filter: invert(39%) sepia(52%) saturate(1158%) hue-rotate(163deg) brightness(95%) contrast(102%);
  height: 65px;
}
ul.icones-ul li span {
  color: #0683bf;
  display: block;
  font-family: Roboto;
  font-size: 18px;
}

.video-program {
  overflow: hidden;
  padding-bottom: 60px;
}
.video-program ul {
  margin-bottom: 15px;
}
.video-program ul li a {
  background-color: #4a4a4a;
  position: relative;
  overflow: hidden;
}
.video-program ul li a img {
  top: 14px;
  left: 13px;
  z-index: 2;
}
.video-program ul li a i {
  position: absolute;
  color: #cc3333;
  font-size: 45px;
  top: 51px;
  left: 90px;
  z-index: 2;
}
.video-program ul li a i:hover {
  color: white;
}
.video-program ul li a div {
  height: 124px;
  width: 193px;
  background-color: #4a4a4a;
}
.video-program .link-more-video {
  color: black;
  font-family: Roboto;
}
.video-program .link-more-video i {
  color: black;
  font-size: 20px;
}
.video-program::after {
  content: "FITGROUPS";
  left: 0;
  right: 0;
  width: 300px;
  margin: 40px auto !important;
}

.fitgroup-program {
  background-color: #444141;
  color: white;
  background-image: url("~/public/img/home/bg-fitgroup.png");
  background-repeat: no-repeat;
  font-family: "Roboto";
  text-align: center;
  font-size: 18px;
  background-position: center 74px;
}
.fitgroup-program button {
  margin: 0 auto;
}

.desc-program {
  padding-top: 50px;
}
.desc-program h4 {
  text-decoration: underline;
}
.desc-program ul li::before {
  content: "o";
  background-color: #cc3333;
  padding: 1px;
  margin-right: 10px;
  color: #cc3333;
  font-size: 5px;
}

#prog-show.fit-hiver .d-md-block h1 {
  color: #03C1DE;
}
#prog-show.fit-hiver .d-block h1 {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(3, 193, 222) 58%);
  color: white;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;
}
#prog-show.fit-hiver .change-color {
  color: #03C1DE;
}
#prog-show.fit-hiver .ul-list-icone i {
  color: #03C1DE;
}
#prog-show.fit-hiver .bg-poster-program {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(3, 193, 222) 58%);
}
#prog-show.fit-hiver .img-smile {
  background-image: url("~/public/img/program/sourirebleu.png");
}
#prog-show.fit-1 #simulator {
  display: block !important;
}
#prog-show.fit-1 .d-md-block h1 {
  color: #E99F82;
}
#prog-show.fit-1 .d-md-block button, #prog-show.fit-1 .d-md-block a {
  display: none;
}
#prog-show.fit-1 .d-block h1 {
  background: rgb(46, 186, 204);
  background: linear-gradient(139deg, rgb(46, 186, 204) 0%, rgb(233, 159, 130) 55%, rgb(254, 209, 131) 100%);
  color: white;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;
}
#prog-show.fit-1 .d-block button {
  display: none;
}
#prog-show.fit-1 .change-color {
  color: #E99F82;
}
#prog-show.fit-1 .ul-list-icone i {
  color: #E99F82;
}
#prog-show.fit-1 .bg-poster-program {
  background: rgb(46, 186, 204);
  background: linear-gradient(139deg, rgb(46, 186, 204) 0%, rgb(233, 159, 130) 55%, rgb(254, 209, 131) 100%);
}
#prog-show.bikini-express .img-smile {
  background-image: url("~/public/img/program/smile-yellow.png");
}
#prog-show.bikini-express .d-md-block h1 {
  color: #FED183;
}
#prog-show.bikini-express .d-block h1 {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(254, 209, 130) 72%);
  color: white;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;
}
#prog-show.bikini-express .change-color {
  color: #FED183;
}
#prog-show.bikini-express .ul-list-icone i {
  color: #FED183;
}
#prog-show.bikini-express .bg-poster-program {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(254, 209, 130) 72%);
}
#prog-show.fit-2 .img-smile {
  background-image: url("~/public/img/program/smile-yellow.png");
}
#prog-show.fit-2 .d-md-block h1 {
  color: #FED183;
}
#prog-show.fit-2 .d-block h1 {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(245, 148, 87) 72%);
  color: white;
  font-weight: bold;
  padding-bottom: 15px;
  padding-top: 15px;
}
#prog-show.fit-2 .change-color {
  color: #FED183;
}
#prog-show.fit-2 .ul-list-icone i {
  color: #FED183;
}
#prog-show.fit-2 .bg-poster-program {
  background: rgb(93, 60, 129);
  background: linear-gradient(139deg, rgb(93, 60, 129) 0%, rgb(245, 148, 87) 72%);
}
#prog-show h1 {
  font-weight: 500;
  text-transform: uppercase;
  font-family: Bebas;
}
#prog-show .ul-list-icone i {
  font-size: 40px;
  margin-bottom: 10px;
}
#prog-show .program-price {
  font-size: 40px;
  font-weight: bold;
}
#prog-show .d-block h1 {
  font-size: 40px;
}
#prog-show .d-block .program-price {
  font-size: 50px;
}
#prog-show .container-mutliple-items #but-left, #prog-show .container-mutliple-items #but-right {
  position: absolute;
  top: 40%;
  bottom: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: white;
  border: none;
}
#prog-show .container-mutliple-items #but-left i, #prog-show .container-mutliple-items #but-right i {
  font-size: 30px;
}
#prog-show .container-mutliple-items #but-right {
  right: -38px;
}
#prog-show .container-mutliple-items #but-left {
  left: -45px;
}
#prog-show .img-smile {
  width: 65px;
  height: 47px;
  background-repeat: no-repeat;
  background-size: 100%;
  margin: 0 auto;
}

.result-simulator {
  background-color: #715490 !important;
  color: white;
  font-family: Roboto-Bold;
}
.result-simulator p {
  font-size: 20px;
}
.result-simulator p #lost-weight {
  font-family: Montserrat-Bold;
  font-size: 60px;
  margin-left: 40px;
}
.result-simulator p #new-weight {
  color: #E99F82;
  font-family: Montserrat-Bold;
}
.result-simulator button, .result-simulator a {
  display: block;
  color: white;
}
.result-simulator a.btn {
  margin: 0 auto;
  margin-bottom: 50px;
  background-color: #E99F82;
}
.result-simulator a {
  font-size: 14px;
}

.list-choice-program div:nth-child(1) {
  color: #E99F82;
  font-size: 40px;
  margin-bottom: 5px;
  font-weight: bold;
}
.list-choice-program div:nth-child(2) {
  background-color: #5D3C81;
  color: white;
  width: 150px;
}

@media screen and (min-width: 943px) {
  .container-mutliple-items {
    width: 800px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 942px) {
  .container-mutliple-items {
    width: 83% !important;
    margin: 0 auto;
  }
}
@media screen and (max-width: 426px) {
  #prog-show .bg-poster-program {
    width: 100% !important;
  }
}
.generic-header.offers-header {
  text-align: center;
  padding-top: 70px;
}
.generic-header.offers-header h1 {
  color: white;
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
}

.offers-body {
  padding-bottom: 74px;
  padding-top: 40px;
}
.offers-body h3 {
  font-family: Roboto;
}
.offers-body h3 span {
  color: #cc3333;
  font-weight: bold;
}
.offers-body .container-offers {
  position: relative;
  margin-top: 63px;
  background-color: #f0f0f0;
}
.offers-body .container-offers::before {
  position: absolute;
  content: "";
  top: -64px;
  left: -43px;
  width: 124px;
  height: 128px;
  background: url("~/public/img/offers/stars-blue.png") center no-repeat;
  z-index: 1;
}
.offers-body .container-offers::after {
  position: absolute;
  content: "";
  bottom: -80px;
  right: -43px;
  width: 124px;
  height: 128px;
  background: url("~/public/img/offers/stars-blue.png") center no-repeat;
  z-index: 1;
}
.offers-body .container-offers .block-offer {
  padding-bottom: 20px;
  background-color: white;
}
.offers-body .container-offers .block-offer .bg-image {
  position: relative;
}
.offers-body .container-offers .block-offer .bg-image .container-promo .block-promo {
  border: 4px solid #cc3333;
  border-radius: 100%;
  padding: 10px;
  width: 115px;
  height: 115px;
}
.offers-body .container-offers .block-offer .bg-image .container-promo .block-promo .text-promo {
  border: 1px solid #cc3333;
  border-radius: 100%;
  width: 100%;
  height: 87px;
  color: white;
  font-size: 20px;
  line-height: 90px;
}
.offers-body .container-offers .block-offer .bg-image .container-promo .block-promo .text-promo.line-through {
  text-decoration: line-through;
}
.offers-body .container-offers .block-offer h2 {
  font-family: Roboto-Bold;
  text-transform: uppercase;
  color: white;
}
.offers-body .container-offers .block-offer .container-price {
  margin-bottom: 15px;
}
.offers-body .container-offers .block-offer .container-price .price {
  display: block;
  font-family: Roboto-Bold;
  font-size: 60px;
  text-align: center;
  color: #cc3333;
}
.offers-body .container-offers .block-offer .container-price .detail-price {
  margin-top: -19px;
  font-family: Roboto-Bold;
  font-size: 14px;
  display: block;
}
.offers-body .container-offers .block-offer button {
  text-transform: uppercase;
  padding-left: 45px;
  padding-right: 45px;
  font-size: 18px;
  font-weight: bold;
}
.offers-body .container-offers #double-offers .bg-image {
  background-image: url("~/public/img/offers/new-bg-offer-duo.jpg");
  width: 100%;
  height: 300px;
  background-position: left;
}
.offers-body .container-offers #simple-offers .bg-image {
  background-image: url("~/public/img/offers/new-bg-offer-solo.jpg");
  width: 100%;
  height: 300px;
  background-position: center;
}

.offers-details {
  background-color: #444141;
  color: white;
  font-family: Roboto;
}
.offers-details .title {
  display: block;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 2px;
}
.offers-details ul {
  display: block;
}
.offers-details ul li {
  margin-bottom: 10px;
}

.generic-header.fitgroups-header {
  background: url("~/public/img/fitgroups/bg-fitgroups-2.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 70px;
}
.generic-header.fitgroups-header h1 {
  color: white;
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
}

.fitgroups-body {
  padding-bottom: 74px;
  padding-top: 40px;
}
.fitgroups-body .cadre {
  text-align: center;
}
.fitgroups-body .cadre span {
  font-weight: bold;
}
.fitgroups-body .cadre .portrait {
  height: 100px;
  width: 140px;
  border: 4px solid transparent;
  margin-bottom: 10px;
  display: block;
}
.fitgroups-body .cadre .portrait.audrey {
  border-color: #3e7a36;
}
.fitgroups-body .cadre .portrait.mathieu {
  border-color: #6785ba;
}
.fitgroups-body .cadre .portrait.capucine {
  border-color: #a57c55;
}
.fitgroups-body .hearder-fitgroup-type {
  background: url("~/public/img/fitgroups/bg-water.jpg");
  background-repeat: repeat-x;
  height: 200px;
  width: 100%;
  background-color: red;
}
.fitgroups-body .hearder-fitgroup-type h2 {
  color: white;
  font-family: SketchCollege;
  text-align: center;
  font-size: 40px;
}
.fitgroups-body .hearder-fitgroup-type .accroche {
  color: white;
  display: block;
  font-family: Roboto-Bold;
  font-size: 20px;
}
.fitgroups-body button, .fitgroups-body input[type=submit] {
  padding-right: 60px;
  padding-left: 60px;
  text-transform: uppercase;
  font-family: Roboto-Bold;
}
.fitgroups-body input[type=text] {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.desc-fitgroup p {
  font-family: Roboto;
}
.desc-fitgroup p span.red {
  color: #cc3333;
}
.desc-fitgroup p span.strong {
  font-family: Roboto-Bold;
}
.desc-fitgroup p span.accroche-size {
  font-size: 28px;
}
.desc-fitgroup #list-accroche span {
  display: block;
  background-color: grey;
  color: white;
}
.desc-fitgroup #list-accroche span.green {
  background-color: #3F9733;
}
.desc-fitgroup #list-accroche span.orange {
  background-color: #BB7B1F;
}
.desc-fitgroup #list-accroche span.gray {
  background-color: #3D3D3B;
}
.desc-fitgroup #list-accroche img {
  cursor: pointer;
}
.desc-fitgroup .title-accroche {
  font-size: 23px;
}

.fitgroups-group span {
  color: #cc3333;
  font-family: Roboto-Bold;
}
.fitgroups-group .brands-fit {
  font-family: Montserrat-Bold;
  color: #cc3333;
}
.fitgroups-group .brands-group-name {
  font-family: Montserrat-Bold;
  color: black;
}

#ajax-return-text {
  display: none;
}

.generic-header.result-header {
  background: url("~/public/img/result/bg-result.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 70px;
}
.generic-header.result-header h1 {
  color: white;
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
}

.result-body {
  padding-bottom: 74px;
  padding-top: 40px;
}
.result-body .result-header-body {
  background-repeat: no-repeat;
  overflow: hidden;
  min-height: 353px;
}
.result-body .result-header-body .infos-result {
  background-color: #8e8c8c;
  color: white;
  font-family: Roboto;
}

.list-portrait {
  margin-bottom: 15px;
}
.list-portrait li {
  margin-bottom: 25px;
}
.list-portrait li a {
  background-color: #4a4a4a;
  position: relative;
  overflow: hidden;
}
.list-portrait li a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}
.list-portrait li a:hover .container-img {
  transform: translate(0px, 20px);
}
.list-portrait li a .container-img {
  position: absolute;
  top: 18px;
  left: 23px;
  transition: transform 1s;
  z-index: 2;
  height: 148px;
  overflow: hidden;
}
.list-portrait li a div.grid {
  height: 151px;
  width: 178px;
  transition: transform 1s;
  background-image: url("~/public/img/result/grid-portrait-result.png");
  background-repeat: no-repeat;
  margin-bottom: 20px;
}
.list-portrait li a .firstname {
  text-transform: capitalize;
  color: black;
  margin-right: 25px;
  margin-left: 25px;
}
.list-portrait li a .lostweight {
  color: black;
  font-weight: bold;
}

.hidden-content {
  display: none;
}
.hidden-content article i {
  margin-right: 5px;
  font-size: 12px;
}
.hidden-content article .bad-weight {
  color: #cc3333;
}
.hidden-content article .good-weight {
  color: green;
}
.hidden-content h2 {
  color: #cc3333;
  font-weight: bold;
}
.hidden-content h4 {
  color: black;
  font-size: 16px;
  font-weight: bold;
}
.hidden-content aside img {
  margin-bottom: 15px;
}
.hidden-content aside .lostweight {
  color: #cc3333;
  display: block;
  text-align: center;
}

#header-page-video {
  background: rgb(96, 62, 126);
  background: linear-gradient(138deg, rgb(96, 62, 126) 0%, rgb(232, 54, 70) 100%);
}

#list-video a {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 426px) {
  #header-page-video img {
    margin-bottom: -92px;
    margin-top: -40px;
    width: 100% !important;
  }
  #header-page-video h1 {
    text-align: center;
  }
  #list-video img {
    width: 100% !important;
    height: 100% !important;
  }
  #list-video a {
    width: 70% !important;
    margin: 0 auto;
    display: inline-block;
  }
}
@media screen and (max-width: 426px) {
  #header-page-aboutus img {
    margin-bottom: 17px;
    margin-top: 22px;
    width: 30% !important;
  }
  #header-page-aboutus h1 {
    text-align: center;
  }
}
@media screen and (min-width: 427px) {
  #header-page-aboutus img {
    margin: 40px;
    width: 10% !important;
  }
}
#page-aboutus {
  padding-bottom: 74px;
}
#page-aboutus #header-page-aboutus {
  background: rgb(184, 36, 129);
  background: linear-gradient(138deg, rgb(184, 36, 129) 0%, rgb(95, 62, 128) 100%);
}
#page-aboutus #header-page-aboutus img {
  width: 50%;
}
#page-aboutus .block-actor {
  margin-bottom: 65px;
}
#page-aboutus .section-actor h2 {
  margin-bottom: 20px;
  font-family: Bebas;
  font-size: 34px;
  color: #5D3C81;
}
#page-aboutus .section-actor .profil-actor {
  margin-bottom: 20px;
}
#page-aboutus .section-actor .profil-actor li.job {
  font-family: Bebas;
}
#page-aboutus .section-actor .profil-actor li.social {
  font-size: 14px;
}
#page-aboutus .section-actor .profil-actor li.social i {
  margin-right: 38px;
  font-size: 16px;
}
#page-aboutus .section-actor .profil-actor li.social a {
  color: black;
}
#page-aboutus .section-actor .profil-actor li.diplome {
  margin-top: 10px;
  font-size: 14px;
}
#page-aboutus .section-actor .profil-actor li.diplome i {
  margin-right: 15px;
  font-size: 16px;
}
#page-aboutus .section-actor .profil-actor li span.red {
  font-family: Bebas;
  font-size: 16px;
  color: #cc3333;
}
#page-aboutus .section-actor .description {
  position: relative;
  text-indent: 4.8em;
  text-align: justify;
  margin-top: 50px;
}
#page-aboutus .section-actor .description:before, #page-aboutus .section-actor .description:after {
  font-size: 60px;
  color: #5D3C81;
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  position: absolute;
}
#page-aboutus .section-actor .description:before {
  content: "\f10d";
  margin-right: 15px;
  left: -1.3em;
  top: -0.8em;
}
#page-aboutus .section-actor .description:after {
  content: "\f10e";
  margin-top: 10px;
  right: 0;
}
#page-aboutus .h2-display-mobile {
  font-family: Bebas;
  font-size: 34px;
}
#page-aboutus .line-team {
  margin-bottom: 81px;
}

.list-testimony span {
  display: block;
  color: #cc3333;
  font-weight: bold;
  text-align: center;
}

.recap-page {
  position: relative;
  border: 2px solid #4a4a4a;
  margin-top: 25px;
  margin-bottom: 40px;
}
.recap-page #truncate-basket {
  position: absolute;
  right: 40px;
  top: -21px;
  color: white;
}
.recap-page #truncate-basket .fa-trash {
  background-color: #4a4a4a;
  padding: 10px;
  border-radius: 100%;
  border-color: white;
  border: 2px solid;
}
.recap-page #truncate-basket .fa-trash:hover {
  background-color: #242424;
}
.recap-page .header-recap {
  padding: 15px;
  border-bottom: 1px solid #4a4a4a;
  margin: 0 !important;
}
.recap-page .header-recap .price-offer, .recap-page .header-recap .price-offer-reduce {
  font-weight: bold;
  font-size: 35px;
}
.recap-page .header-recap .price-offer {
  color: #cc3333;
}
.recap-page .header-recap .price-offer-reduce {
  color: #0683bf;
}
.recap-page .header-recap h2 {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
}
.recap-page .header-recap #show-code-promo {
  background-color: #0683bf;
  color: white;
  padding: 5px 10px 5px 10px;
  display: inline;
  border-radius: 5px;
  font-size: 12px;
}
.recap-page .header-recap #show-code-promo #title-code-promo {
  font-weight: bold;
  font-size: 14px;
}
.recap-page .header-recap .reduce-price-promo {
  font-weight: bold;
}
.recap-page .line-recap {
  padding: 15px;
}
.recap-page .line-recap + .line-recap {
  border-top: 1px solid #4a4a4a;
}
.recap-page .line-recap .price-offer {
  font-weight: bold;
  font-size: 35px;
  color: #cc3333;
}
.recap-page .line-recap h2 {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
}
.recap-page .person-line .list-data-user {
  color: #4a4a4a;
  font-size: 12px;
}
.recap-page .person-line .list-data-user i {
  color: black;
  margin-right: 4px;
  font-size: 14px;
}
.recap-page .person-line .lost-weight {
  color: #4a4a4a;
  font-size: 12px;
}
.recap-page .person-line .lost-weight span {
  font-weight: bold;
  font-size: 14px;
}
.recap-page .person-line .infos-weight span {
  display: block;
}
.recap-page .person-line .infos-weight .new-weight {
  font-size: 25px;
  color: #cc3333;
  font-weight: bold;
}
.recap-page .person-line .person i {
  color: #4a4a4a;
  font-size: 20px;
  margin-right: 5px;
}
.recap-page .person-line .person span {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: bold;
  font-family: Montserrat-Bold;
}
.recap-page .person-line .title-program {
  font-family: Montserrat-Bold;
  text-transform: uppercase;
  color: #cc3333;
  font-weight: bold;
}
.recap-page .toggle-detail-persons {
  cursor: pointer;
  position: absolute;
  bottom: -27px;
  width: 100%;
  text-align: center;
}
.recap-page .toggle-detail-persons span {
  background-color: #4a4a4a;
  color: white;
  padding: 5px 40px 5px 40px;
  border-radius: 0 0 3.125rem 3.125rem;
  border-top-width: 0;
  z-index: -1;
}

.container-user-error .text-error {
  color: #cc3333;
  display: block;
  font-weight: bold;
  font-size: 14px;
}
.container-user-error input {
  border-color: #cc3333;
}

.page-admin .container-nav {
  background-color: black;
}
.page-admin .container-nav header {
  color: white;
  text-align: center;
}
.page-admin .container-nav .nav-admin {
  margin-top: 20px;
  margin-bottom: 40px;
}
.page-admin .container-nav .nav-admin li {
  background-color: #333333;
  margin-bottom: 2px;
  padding: 10px;
}
.page-admin .container-nav .nav-admin li:hover {
  background-color: white;
  cursor: pointer;
}
.page-admin .container-nav .nav-admin li:hover a {
  color: black;
}
.page-admin .container-nav .nav-admin li:hover a i {
  color: black;
}
.page-admin .container-nav .nav-admin li a {
  color: white;
}
.page-admin .container-nav .nav-admin li a i {
  color: white;
}

.page-validation {
  padding-top: 25px;
  padding-bottom: 25px;
}
.page-validation .header {
  font-weight: bold;
  font-size: 30px;
}
.page-validation .header .congratulation {
  color: #cc3333;
  font-size: 40px;
}
.page-validation input[type=text] {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.page-validation .indication {
  text-decoration: underline;
}
.page-validation .link {
  color: black;
  text-decoration: underline;
}
.page-validation form a {
  color: black;
}
.page-validation form a:hover {
  text-decoration: none;
}
.generic-header.instagram-header {
  background: url("~/public/img/instagram/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: left;
  padding-top: 70px;
  min-height: 451px;
  background-position: center;
}
.generic-header.instagram-header h1 {
  color: #cc3333;
  font-family: "Montserrat-Bold";
  text-transform: uppercase;
  font-weight: bold;
}
.generic-header.instagram-header #accroche {
  padding: 0;
  margin: 0;
}
.generic-header.instagram-header #accroche li {
  display: block;
  color: white;
  font-size: 30px;
  font-family: "Montserrat-Bold";
  font-weight: bold;
}
.generic-header.instagram-header #accroche li:first-child::before {
  background-color: #0683bf;
}
.generic-header.instagram-header #accroche li::before {
  height: 32px;
  width: 10px;
  background-color: #4a4a4a;
  content: " ";
  display: inline-block;
  margin-right: 20px;
}

.instagram-body {
  padding-bottom: 74px;
}
.instagram-body h2, .instagram-body h4 {
  font-family: Roboto-Bold;
}
.instagram-body .block-event-soon {
  background-color: #c9c9c9;
  padding: 60px;
}
.instagram-body .block-event-soon .block-right {
  background-color: white;
  padding: 20px;
}
.instagram-body .block-event-soon .block-right ul li i {
  width: 20px;
  color: #313131;
}
.instagram-body .subscript-instagram {
  padding: 60px;
}
.instagram-body .subscript-instagram .accroche {
  font-family: Roboto-Bold;
  font-size: 28px;
  color: black;
}
.instagram-body .subscript-instagram .accroche a {
  color: #cc3333;
  font-size: 26px;
}
.instagram-body .subscript-instagram .line {
  display: block;
}
.instagram-body .subscript-instagram .line.number {
  color: #4a4a4a;
  font-size: 20px;
}
.instagram-body .list-events li a {
  color: black;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#page-listevent {
  /*.btn:hover, .btn.js-active {
    color: #fff;
    background-color: #2c2c2c;
  }*/
}
#page-listevent .btn:last-child {
  margin-right: 0;
}
#page-listevent .header {
  max-width: 500px;
  margin: 50px auto;
  text-align: center;
}
#page-listevent .header__title {
  margin-bottom: 30px;
  font-weight: 600;
}
#page-listevent .content {
  max-width: 800px;
  width: 95%;
  margin: 0 auto 40px;
}
#page-listevent .content__title {
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}
#page-listevent .timeline {
  position: relative;
  min-height: 500px;
  width: 100%;
  padding: 60px 0;
}
#page-listevent .timeline__bar {
  position: absolute;
  top: 0;
  left: 50%;
  width: 6px;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: linear-gradient(#4a4a4a, black);
}
#page-listevent .timeline__bar:before, #page-listevent .timeline__bar:after {
  position: absolute;
  left: 50%;
  display: block;
  width: 12px;
  height: 12px;
  content: "";
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
}
#page-listevent .timeline__bar:before {
  top: -1px;
  background-color: #4a4a4a;
}
#page-listevent .timeline__bar:after {
  bottom: -1px;
  background-color: black;
}
#page-listevent .timeline__elem {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}
#page-listevent .timeline__elem:last-child {
  margin-bottom: 0;
}
@media (min-width: 900px) {
  #page-listevent .timeline__elem {
    flex-direction: row;
  }
}
@media (min-width: 900px) {
  #page-listevent .timeline__elem--right {
    justify-content: flex-end;
  }
}
#page-listevent .timeline__date {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  margin: auto;
  color: #cc3333;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 6px solid #cc3333;
  background-color: white;
}
@media (min-width: 900px) {
  #page-listevent .timeline__date {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
#page-listevent .timeline__date-day {
  font-size: 1.35rem;
  font-weight: 600;
}
#page-listevent .timeline__date-month {
  font-size: 0.85rem;
  font-weight: 600;
}
#page-listevent .timeline__event {
  position: relative;
  width: 90%;
  margin: 30px auto 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
@media (min-width: 560px) {
  #page-listevent .timeline__event {
    width: 65%;
  }
}
@media (min-width: 900px) {
  #page-listevent .timeline__event {
    width: calc((100% - 70px - 32px) / 2);
    margin: 0;
  }
}
#page-listevent .timeline__event:before {
  position: absolute;
  top: 50%;
  display: none;
  width: 0;
  height: 0;
  content: "";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
#page-listevent .timeline__event:after {
  position: absolute;
  top: 50%;
  display: none;
  width: 16px;
  height: 16px;
  content: "";
  -webkit-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
}
@media (min-width: 900px) {
  #page-listevent .timeline__event:before, #page-listevent .timeline__event:after {
    display: block;
  }
}
#page-listevent .timeline__event--left:before {
  right: -8px;
  border-left: 8px solid #fff;
}
#page-listevent .timeline__event--left:after {
  right: -7px;
  box-shadow: 2px -1px 1px 0 rgba(0, 0, 0, 0.08);
}
#page-listevent .timeline__event--right:before {
  left: -8px;
  border-right: 8px solid #fff;
}
#page-listevent .timeline__event--right:after {
  left: -7px;
  box-shadow: -2px 2px 1px 0 rgba(0, 0, 0, 0.08);
}
#page-listevent .timeline__event-date-time {
  padding: 20px 15px 0;
  display: flex;
  margin-bottom: 15px;
  font-size: 0.8rem;
  color: rgba(44, 44, 44, 0.7);
}
#page-listevent .timeline__event-date {
  margin-right: 10px;
}
#page-listevent .timeline__event-title {
  padding: 0 15px;
  margin-bottom: 15px;
  font-size: 1.25rem;
  font-weight: 600;
}
#page-listevent .timeline__event-descr {
  padding: 0 15px 20px;
  font-size: 0.9rem;
  line-height: 1rem;
}
#page-listevent .timeline__event-descr .badge-price {
  font-size: 18px;
  color: #0683bf;
  font-weight: bold;
  display: block;
}
#page-listevent .timeline__event-descr a {
  color: black;
}
#page-listevent .timeline__event-actions {
  padding: 20px 15px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
}
#page-listevent .timeline__event-action {
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: linear;
  transition-delay: 0s;
  margin-right: 24px;
  font-size: 0.9rem;
  text-transform: uppercase;
}
#page-listevent .event__description {
  display: none;
}

#body-paiement {
  background-color: #eceaea;
}
#body-paiement header {
  padding-bottom: 30px;
  padding-top: 30px;
  display: table;
  white-space: nowrap;
  text-align: center;
}
#body-paiement header:before, #body-paiement header:after {
  content: "";
  display: table-cell;
  width: 50%;
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=) repeat-x left center;
}
#body-paiement header span {
  background-color: #4a4a4a;
  color: white;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  font-size: 16px;
  display: block;
  margin: 0 auto;
  width: 240px;
  text-align: center;
  font-weight: bold;
}
#body-paiement .icon {
  text-align: center;
}
#body-paiement .icon i {
  display: block;
  color: #4a4a4a;
  font-size: 80px;
}
#body-paiement .price {
  color: #0683bf;
  font-size: 25px;
  font-weight: bold;
}

#page-paiement .bg-paiement {
  background: rgb(17, 191, 217);
  background: linear-gradient(128deg, rgb(17, 191, 217) 0%, rgb(253, 206, 130) 82%);
}
#page-paiement .block-form {
  border: 1px solid #dfdfdf;
  background-color: white;
  border-radius: 10px;
  padding: 35px;
  margin: 0 auto;
}
#page-paiement #button-addon2 {
  background-color: #BC1B82;
  color: white;
}
#page-paiement .list-data-user i {
  width: 22px;
}

@media screen and (max-width: 426px) {
  #page-bookgold header#main-header-page-book img {
    width: 100% !important;
  }
  #page-bookgold header#main-header-page-book h1 {
    text-align: center;
  }
}
#page-bookgold header#main-header-page-book {
  background: rgb(253, 203, 127);
  background: linear-gradient(159deg, rgb(253, 203, 127) 0%, rgb(244, 56, 63) 82%);
  padding: 25px;
}
#page-bookgold header#main-header-page-book h1 {
  color: white;
}
#page-bookgold .book-text {
  background-color: #F0F0F0;
  position: relative;
}
#page-bookgold .book-text header h2 {
  color: #444141;
}
#page-bookgold .book-text header span {
  font-style: italic;
  font-size: 12px;
}
#page-bookgold .book-text div {
  color: #444141;
}
#page-bookgold .book-text:before {
  content: " ";
  display: block;
  position: absolute;
  top: 30px;
  left: -30px;
  width: 30px;
  height: 30px;
  border: 15px solid transparent;
  border-right: 15px solid #F0F0F0;
}

#showroom .link-show-item {
  text-decoration: none;
  color: inherit;
}
#showroom .link-show-item .item-name {
  font-size: 20px;
}
#showroom .link-show-item .item-pricediscount {
  color: #cc3333;
  font-size: 18px;
}
#showroom .link-show-item .item-discount-price {
  text-decoration: line-through;
  font-size: 14px;
}
#showroom .link-show-item .item-price {
  font-size: 18px;
}
#showroom .link-show-item .poster {
  object-fit: cover;
  vertical-align: bottom;
  height: 450px;
  width: 449px;
}

#showitem #back {
  color: black;
}
#showitem #back i {
  color: darkred;
}
#showitem #showitemdetails .item-pricediscount {
  color: #0683bf;
  font-weight: bold;
  font-size: 35px;
  margin-right: 20px;
}
#showitem #showitemdetails .item-discount-price {
  font-size: 20px;
  text-decoration: line-through;
}
#showitem #showitemdetails .item-price {
  font-size: 35px;
  color: #cc3333;
  font-weight: bold;
}
#showitem #showitemdetails #selectsize {
  border: 1px solid black;
}
#showitem .dropdown-item.disable {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}
#showitem .poster-mini {
  width: 150px;
  height: 122px;
  object-fit: cover;
}
#showitem #portrait-img {
  object-fit: scale-down;
  width: 100%;
  height: 463px;
}
#showitem .swiper-img {
  height: 300px;
  width: 100% !important;
  object-fit: scale-down;
}

#showitembasket .delete-item {
  color: grey;
}

.simulator-poping {
  padding: 20px;
  position: relative;
}
.simulator-poping .logo {
  margin-bottom: 25px;
}
.simulator-poping .commun-section h4 {
  display: block;
  width: 100%;
  font-family: Bebas;
  font-size: 28px;
  line-height: 20px;
}
.simulator-poping .fitname {
  font-size: 20px;
  font-weight: bold;
}
.simulator-poping .fitname i {
  color: black;
  font-size: 40px;
}
.simulator-poping .banner-offer h4 {
  margin-bottom: 50px;
}
.simulator-poping .banner-offer .list-offer {
  display: block;
  width: 100%;
  margin-bottom: 40px;
}
.simulator-poping .banner-offer .list-offer li .container-offers {
  cursor: pointer;
  border: 3px transparent dashed;
  background-clip: padding-box;
  border-radius: 15%;
  height: 166px;
  width: 166px;
  padding: 5px;
}
.simulator-poping .banner-offer .list-offer li .container-offers.selected {
  border: 3px #cc3333 dashed;
}
.simulator-poping .banner-offer .list-offer li .container-offers#first-offer {
  background-image: url("~/public/img/offers/new-offer-166x166.png");
}
.simulator-poping .banner-offer .list-offer li .container-offers#second-offer {
  background-image: url("~/public/img/offers/new-offer2-166x166.png");
}
.simulator-poping .banner-offer .list-offer li .container-offers h2 {
  color: white;
  text-transform: uppercase;
  font-size: 20px;
}
.simulator-poping .banner-offer .list-offer li .container-offers .price {
  text-align: center;
}
.simulator-poping .banner-offer .list-offer li .container-offers .price .real-price {
  color: #cc3333;
  font-size: 25px;
  font-weight: bold;
  display: block;
}
.simulator-poping .banner-offer .list-offer li .container-offers .price .promo {
  display: block;
  color: white;
}
.simulator-poping .banner-person {
  display: none;
}
.simulator-poping .banner-person header {
  margin-bottom: 50px;
}
.simulator-poping .banner-person header h4 {
  margin-bottom: 20px;
}
.simulator-poping .banner-person header div {
  display: block;
  width: 100%;
  text-align: center;
}
.simulator-poping .banner-person header div i {
  font-size: 20px;
  margin-right: 10px;
}
.simulator-poping .banner-person .row-user {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}
.simulator-poping .banner-person .row-user label.error {
  color: #cc3333;
  font-family: Roboto-Bold;
}
.simulator-poping .banner-person .row-user input[type=text] {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.simulator-poping .banner-person .row-user .woman-body {
  display: none;
}
.simulator-poping .banner-person .row-user .list-choice-body li div {
  border: 2px solid transparent;
  padding: 5px;
  cursor: pointer;
}
.simulator-poping .banner-person .row-user .list-choice-body li div span {
  display: block;
  text-align: center;
}
.simulator-poping .banner-person .row-user .list-choice-body li div i {
  font-size: 16px;
}
.simulator-poping .banner-person .row-user .list-choice-body li.selected div {
  border: 2px dashed #0683bf;
  background-clip: padding-box;
  border-radius: 15%;
}
.simulator-poping .banner-person .row-user .list-choice-body li.selected div i {
  color: #0683bf;
}
.simulator-poping .banner-person .row-user .infos-user {
  display: block;
  font-size: 15px;
  color: #4a4a4a;
  text-align: center;
}
.simulator-poping .banner-person label.user-label {
  font-family: Roboto-Bold;
}
.simulator-poping .banner-person label.user-label.block {
  display: block;
}
.simulator-poping button {
  padding-right: 5.5em;
  padding-left: 5.5em;
  display: block;
}

.creditcard {
  width: 500px;
  background: url("~/public/img/recap/ccBackBg.png") 0 0 repeat-x;
  border-radius: 15px;
  height: 260px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(119, 119, 119, 0.5);
  box-shadow: 0 2px 5px 0 rgba(119, 119, 119, 0.5);
  position: relative;
  overflow: hidden;
}
.creditcard:after {
  content: "";
  display: block;
  height: 100%;
  width: 70px;
  top: 0;
  right: 106px;
  position: absolute;
  background: -moz-linear-gradient(left, rgba(0, 0, 0, 0.33) 1%, rgba(0, 0, 0, 0.33) 20%, rgba(0, 0, 0, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.33) 1%, rgba(0, 0, 0, 0.33) 20%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0.33) 1%, rgba(0, 0, 0, 0.33) 20%, rgba(0, 0, 0, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#54000000", endColorstr="#00000000", GradientType=1);
}
.creditcard .ccfront {
  height: 260px;
  width: 354px;
  background: url("~/public/img/recap/ccFrontBg.png") 0 0 repeat-x;
  float: left;
  border-radius: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #ffffff;
  z-index: 1;
  position: relative;
  padding: 15px;
}
.creditcard #cvc {
  position: absolute;
  right: 23px;
  top: 92px;
  width: 61px;
}
.creditcard .row-card {
  padding: 10px;
  background-color: white;
  margin-bottom: 15px;
}

.popin-sponsor .fancybox-slide--iframe .fancybox-content {
  width: 450px !important;
  min-height: 700px;
  max-width: 80%;
  max-height: 80%;
  margin: 0;
}

.blocker {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 99;
  padding: 20px;
  box-sizing: border-box;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  text-align: center;
}

.blocker:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -0.05em;
}

.blocker.behind {
  background-color: transparent;
}

.modalcustom {
  display: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  max-width: 600px;
  box-sizing: border-box;
  width: 90%;
  background: #fff;
  padding: 30px 30px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -o-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px #000;
  -moz-box-shadow: 0 0 10px #000;
  -o-box-shadow: 0 0 10px #000;
  -ms-box-shadow: 0 0 10px #000;
  box-shadow: 0 0 10px #000;
  text-align: left;
}
.modalcustom .content {
  color: #8996a2;
  font-size: 1.6rem;
}
.modalcustom.template-popin-account {
  padding: 0 !important;
  border-radius: 0px;
}
.modalcustom.template-popin-account header {
  padding: 30px;
  background-color: #8273f6;
  color: white;
}
.modalcustom.template-popin-account .content {
  padding: 20px 30px;
}
.modalcustom.template-popin-account .content .but-submit {
  margin-top: 40px;
  padding: 0px 20px 0px 20px;
  height: 35px;
  font-size: 15px;
  min-width: 50%;
  margin: 0 auto;
}
.modalcustom#popin-menu a {
  text-decoration: none;
  height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 16px;
}
.modalcustom#popin-menu .menu-content {
  display: none;
}
.modalcustom#popin-menu .menu-content#reactivte-sub h4 {
  margin-bottom: 32px;
  color: black;
  font-size: 18px;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer {
  padding-bottom: 20px;
  padding-top: 20px;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer + .line-offer {
  border-top: 1px solid #e3e4e4;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer .title-offer {
  color: black;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer .grab {
  font-size: 14px;
}
.modalcustom#popin-menu .menu-content#reactivte-sub .line-offer button {
  height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.modalcustom a.close-modalcustom {
  position: absolute;
  top: -12.5px;
  right: -12.5px;
  display: block;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==");
}

.modalcustom-spinner {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  padding: 12px 16px;
  border-radius: 5px;
  background-color: #111;
  height: 20px;
}

.modalcustom-spinner > div {
  border-radius: 100px;
  background-color: #fff;
  height: 20px;
  width: 2px;
  margin: 0 1px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.modalcustom-spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.modalcustom-spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.modalcustom-spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.u-spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
  visibility: hidden;
  opacity: 0;
  z-index: 99999999;
}
.u-spinner.open {
  visibility: visible;
  opacity: 1;
}
.u-spinner svg {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  fill: #ffffff;
  font-size: 12rem;
}
.u-spinner .svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  overflow: hidden;
}

/********* Banners Home ************/
@media screen and (max-width: 426px) {
  #rubina-testimony {
    padding-top: 15px;
  }
  #rubina-testimony img {
    width: 100%;
  }
  #program-top-top {
    background-repeat: no-repeat;
    background-image: url("~/public/img/program/bg-program2.jpg") !important;
  }
  #program-express-summer {
    background-repeat: no-repeat;
    background-image: url("~/public/img/program/bg-program3.jpg") !important;
  }
  .banner-program#program-top-top, .banner-program#program-express-summer {
    background-size: cover;
    background-position: center;
    min-height: 646px !important;
    padding-top: 60px;
  }
  .banner-program#program-top-top button, .banner-program#program-top-top a, .banner-program#program-top-top h2, .banner-program#program-express-summer button, .banner-program#program-express-summer a, .banner-program#program-express-summer h2 {
    display: none;
  }
  .banner-home h2 {
    font-size: 1.3em !important;
    margin-top: 141px;
    background-color: black;
    padding: 7px;
  }
  .fitgroups-body .container-content {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .creditcard {
    width: 100% !important;
  }
  .creditcard .ccfront {
    display: block;
    width: 100% !important;
  }
  .instagram-body .block-event-soon {
    padding: 0 !important;
  }
  .banner-home {
    background-image: url("~/public/img/home/mbavis3.jpg");
    background-size: contain;
    min-height: 307px;
  }
  .banner-home .card-item {
    background-color: #4a4a4a;
  }
  .mobile-bg {
    background-image: url("~/public/img/home/fitnessavoda-video-exercice.jpg");
    background-size: cover;
    background-position: center center;
    padding-top: 40px;
    padding-bottom: 30px;
    background-repeat: no-repeat;
  }
  .fitgroup-home {
    padding: 65px 10px 10px 10px !important;
  }
  .aboutus-body .fitgroup-home {
    padding: 100px 10px 10px 10px !important;
  }
  #banner-video-mobile {
    display: block;
  }
  #banner-video-mobile i {
    position: absolute;
    color: #cc3333;
    font-size: 60px;
    left: 42%;
    right: 50%;
    bottom: 50%;
    top: 39%;
  }
  #banner-video-mobile i:hover {
    color: white;
  }
  #banner-video-mobile ul li {
    margin-bottom: 5px;
  }
  .banner-video {
    display: none;
  }
  .why-us-home h3 {
    padding: 20px !important;
  }
}
@media screen and (min-width: 427px) {
  #rubina-testimony img {
    width: 75%;
  }
  #banner-video-mobile {
    display: none;
  }
  .banner-video {
    display: block;
  }
  .banner-home {
    background-size: cover;
    background-position: center center;
    min-height: 475px;
    background-image: url("~/public/img/home/home-2.png");
  }
  .presentation-video-exrcices {
    background-image: url("~/public/img/home/bg-video-exercices.png");
  }
  .before-home .user-text #notice-user::after {
    position: absolute;
    content: "";
    white-space: pre-wrap;
    color: white;
    bottom: 69px;
    right: -43px;
    width: 124px;
    height: 128px;
    background: url("~/public/img/home/stars.png") center no-repeat;
    font-weight: bold;
    z-index: 1;
    padding-top: 50px;
    padding-left: 28px;
  }
}
@media screen and (max-width: 968px) {
  .nav {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}