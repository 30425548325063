.banner-program{
  width: 100%;
  min-height: 620px;
  background-repeat: no-repeat;background-image: url('~/public/img/program/bg-2.jpg');

  //background-attachment: fixed;
  background-size: cover;
  background-position:center;
  padding-top: 100px;
  padding-bottom: 30px;
  h1{
    text-align: center;
    color: $white;
    text-transform: uppercase;
    font-family: Montserrat-Bold;
    font-size: 30px;
    margin-bottom: 30px;
    span{
      color: $red;
    }
  }
  h2{
    text-align: center;
    font-family: Montserrat;
    color: $white;
    text-transform: uppercase;
    font-size: 25px;
    margin-bottom: 55px;
  }
  ul{
    width:100%;
    margin: 0 auto;
    margin-bottom: 100px;
    li{
      text-align: center;

      i,div{
        background-color: white;
        color: $blue;
        font-size: 35px;
        height: 100px;
        width: 100px;
        border-radius: 100%;
        border: 2px $blue dashed;
        margin-bottom: 10px;

      }
      i{
        line-height: 94px;
      }
      div{
        margin:0 auto;
        padding:15px;
        img{
          fill:red;
         filter: invert(39%) sepia(52%) saturate(1158%) hue-rotate(163deg) brightness(95%) contrast(102%);
          height:65px;
        }
      }

      span{
        color: $white;
        display: block;
        font-family: Roboto;
        font-size: 18px;
      }
    }
  }
  .call-action{
    margin: 0 auto;
    display: block;
    padding-left: 50px;
    padding-right: 50px;
  }
}
ul.icones-ul{
  width:100%;
  margin: 0 auto;
  margin-bottom: 100px;
  li{
    text-align: center;

    i,div{
      background-color: white;
      color: $blue;
      font-size: 35px;
      height: 100px;
      width: 100px;
      border-radius: 100%;
      border: 2px $blue dashed;
      margin-bottom: 10px;

    }
    i{
      line-height: 94px;
    }
    div{
      margin:0 auto;
      padding:15px;
      img{
        fill:red;
        filter: invert(39%) sepia(52%) saturate(1158%) hue-rotate(163deg) brightness(95%) contrast(102%);
        height:65px;
      }
    }

    span{
      color: $blue;
      display: block;
      font-family: Roboto;
      font-size: 18px;
    }
  }
}
.video-program{
  overflow: hidden;
  padding-bottom: 60px;
  ul{
    margin-bottom: 15px;
    li{

      a{
        background-color: $grey;
        position: relative;
        overflow: hidden;
        img{
          top: 14px;
          left: 13px;
          z-index:2;
        }
        i{
          position: absolute;
          color: $red;
          font-size:45px;
          top: 51px;
          left: 90px;
          z-index:2;
          &:hover{
            color:white;
          }
        }
        div{


          height: 124px;
          width: 193px;

          background-color: $grey;
        }
      }
    }
  }
  .link-more-video{
    color: black;
    font-family: Roboto;
    i{
      color: black;
      font-size: 20px;
    }
  }
  &::after{
    @extend .transition;
    content: "FITGROUPS";
    left: 0;
    right:0;
    width: 300px;
    margin: 40px auto!important;
  }
}
.fitgroup-program{
  background-color: #444141;
  color: white;
  background-image: url('~/public/img/home/bg-fitgroup.png');
  background-repeat: no-repeat;

  font-family:"Roboto";
  text-align: center;
  font-size: 18px;
  background-position: center 74px;
  button{
    margin:0 auto;
  }
}

.desc-program{
  padding-top: 50px;
  h4{
    text-decoration:underline;
  }
  ul{
    li{
      &::before{
        content: 'o';
        background-color: $red;
        padding:1px;
        margin-right: 10px;
        color:$red;
        font-size:5px;
      }
    }
  }
}

#prog-show{
  &.fit-hiver{
    .d-md-block{
      h1{
        color:$blue-light;
      }
    }
    .d-block{
      h1{
        background: rgb(93,60,129);
        background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(3,193,222,1) 58%);
        color:white;
        font-weight: bold;
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }
    .change-color{
      color:$blue-light;
    }
    .ul-list-icone{
      i{
        color:$blue-light;
      }
    }
    button.btn, a.btn{
      @extend .btn-blue;
    }
    .bg-poster-program{
      background: rgb(93,60,129);
      background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(3,193,222,1) 58%);
    }
    .img-smile{
      background-image: url("~/public/img/program/sourirebleu.png");
    }
  }
  &.fit-1{
    #simulator{
      display:block!important;
    }
    .d-md-block{
      h1{
        color:$brown-light;
      }
      button,a{
        display:none;
      }
    }
    .d-block{
      h1{
        background: rgb(46, 186, 204);
        background: linear-gradient(139deg, rgb(46, 186, 204) 0%, rgb(233, 159, 130) 55%, rgb(254, 209, 131) 100%);
        color:white;
        font-weight: bold;
        padding-bottom: 15px;
        padding-top: 15px;
      }
      button{
        display:none;
      }
    }

    .change-color{
      color:$brown-light;
    }
    .ul-list-icone{
      i{
        color:$brown-light;
      }
    }
    button.btn, a.btn{
      @extend .btn-brown;
    }
    .bg-poster-program{
      background: rgb(46, 186, 204);
      background: linear-gradient(139deg, rgb(46, 186, 204) 0%, rgb(233, 159, 130) 55%, rgb(254, 209, 131) 100%);

    }
  }
  &.bikini-express{
    .img-smile{
      background-image: url("~/public/img/program/smile-yellow.png");
    }
    .d-md-block{
      h1{
        color:$yellow-light;
      }
    }
    .d-block{
      h1{
        background: rgb(93,60,129);
        background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(254,209,130,1) 72%);
        color:white;
        font-weight: bold;
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }

    .change-color{
      color:$yellow-light;
    }
    .ul-list-icone{
      i{
        color:$yellow-light;
      }
    }
    button.btn, a.btn{
      @extend .btn-yellow;
    }
    .bg-poster-program{
      background: rgb(93,60,129);
      background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(254,209,130,1) 72%);
    }
  }
  &.fit-2{
    .img-smile{
      background-image: url("~/public/img/program/smile-yellow.png");
    }
    .d-md-block{
      h1{
        color:$yellow-light;
      }
    }
    .d-block{
      h1{
        background: rgb(93,60,129);
        background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(245,148,87,1) 72%);
        color:white;
        font-weight: bold;
        padding-bottom: 15px;
        padding-top: 15px;
      }
    }

    .change-color{
      color:$yellow-light;
    }
    .ul-list-icone{
      i{
        color:$yellow-light;
      }
    }
    button.btn, a.btn{
      @extend .btn-yellow;
    }
    .bg-poster-program{
      background: rgb(93,60,129);
      background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(245,148,87,1) 72%);
    }
  }
  h1{
    font-weight: 500;
    text-transform: uppercase;
    font-family: Bebas;
  }
  .ul-list-icone{
    i{
      font-size: 40px;
      margin-bottom: 10px;
    }
  }
  .program-price{
    font-size: 40px;
    font-weight: bold;
  }
  .d-block{
    h1{
      font-size: 40px;
    }
    .program-price{
      font-size: 50px;
    }
  }
  .container-mutliple-items{
    #but-left,#but-right{
      position:absolute;
      top: 40%;
      bottom: 50%;
      height:40px;
      width: 40px;
      cursor:pointer;
      background-color: white;
      border:none;
      i{
          font-size: 30px;
       }
    }
    #but-right{
      right: -38px;
    }
    #but-left{
      left:-45px;
    }
  }
  .img-smile{
    width: 65px;
    height: 47px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 auto;
  }
}
.result-simulator{
  background-color:#715490!important;
  color:white;
  font-family:Roboto-Bold;
  p{
    font-size: 20px;
    #lost-weight{
      font-family: Montserrat-Bold;
      font-size: 60px;
      margin-left: 40px;
    }
    #new-weight{
      color: #E99F82;
      font-family: Montserrat-Bold;
    }
  }
  button,a{
    display: block;
    color: white;

  }
  a.btn{
    margin: 0 auto;
    margin-bottom: 50px;
    background-color: #E99F82;
  }
  a{
    font-size: 14px;
  }
}

.list-choice-program{
  div:nth-child(1){
    color:$brown-light;
    font-size: 40px;
    margin-bottom: 5px;
    font-weight: bold;
  }
  div:nth-child(2){
    background-color: $purple;
    color:white;
    width: 150px;
  }
}

@media screen and (min-width: 943px){
  .container-mutliple-items{
    width:800px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 942px){
  .container-mutliple-items{
    width:83%!important;
    margin: 0 auto;
  }
}
@media screen and (max-width: 426px){
  #prog-show{
    .bg-poster-program {
      width: 100%!important;
    }
  }

}