#body-paiement{
   background-color: #eceaea;
  header{
    padding-bottom: 30px;
    padding-top: 30px;
    display: table;
    white-space: nowrap;
    text-align: center;

    &:before,&:after{
      content: "";
      display: table-cell;
      width: 50%;
      background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=) repeat-x left center;
    }

    span{
      background-color:#4a4a4a;
      color:white;
      padding:5px 10px 5px 10px;
      border-radius:5px;
      font-size:16px;
      display:block;
      margin: 0 auto;
      width:240px;
      text-align:center;
      font-weight:bold;
    }
  }
  .icon{
    text-align: center;
    i{
      display:block;
      color:#4a4a4a;
      font-size:80px;
    }
  }
  .price{
    color:$blue;
    font-size:25px;
    font-weight:bold;
  }
}

#page-paiement{
  .bg-paiement{
    background: rgb(17,191,217);
    background: linear-gradient(128deg, rgba(17,191,217,1) 0%, rgba(253,206,130,1) 82%);
  }
  .block-form{
    border: 1px solid #dfdfdf;
    background-color: white;
    border-radius: 10px;
    padding: 35px;
    margin: 0 auto;
  }
  #button-addon2{
    background-color: #BC1B82;
    color:white;
  }
  .list-data-user{
    i{
      width: 22px;
    }
  }
}