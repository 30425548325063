.list-testimony{
  span{
    display:block;
    color:$red;
    font-weight:bold;
    text-align : center;
  }
}
.recap-page{
  position:relative;
  border:2px solid $grey;
  margin-top: 25px;
  margin-bottom: 40px;

  #truncate-basket{
    position: absolute;
    right: 40px;
    top: -21px;
    color: white;
    .fa-trash{
      background-color: $grey;
      padding: 10px;
      border-radius: 100%;
      border-color: white;
      border: 2px solid;
      &:hover{
        background-color: darken($grey,15%);
      }

    }
  }

  .header-recap{
    padding: 15px;
    .price-offer,.price-offer-reduce{
      font-weight:bold;
      font-size: 35px;

    }
    .price-offer{
      color:$red;
    }
    .price-offer-reduce{
      color:$blue;
    }


    h2{
      font-family: Montserrat-Bold;
      text-transform: uppercase;
    }
    border-bottom:1px solid $grey;
    margin:0!important;
    #show-code-promo{
      background-color:$blue;
      color: white;
      padding: 5px 10px 5px 10px;
      display: inline;
      border-radius: 5px;
      font-size: 12px;
      #title-code-promo{
        font-weight:bold;
        font-size:14px;
      }
    }
    .reduce-price-promo{
      font-weight:bold;
    }
  }
  .line-recap{
    &+.line-recap{
      border-top: 1px solid $grey;
    }
    padding: 15px;
    .price-offer{
      font-weight:bold;
      font-size: 35px;
      color:$red;
    }
    h2{
      font-family: Montserrat-Bold;
      text-transform: uppercase;
    }
  }
  .person-line{
    .list-data-user{
      color: $grey;
      font-size: 12px;
      i{
        color: black;
        margin-right: 4px;
        font-size: 14px;
      }
    }
.lost-weight{
  color: $grey;
  font-size: 12px;
  span{
    font-weight: bold;
    font-size: 14px;
  }
}
    .infos-weight{
      span{
        display: block;
      }
      .new-weight{
        font-size: 25px;
        color: $red;
        font-weight: bold;
      }
    }
    .person{
      i{
        color: $grey;
        font-size: 20px;
        margin-right: 5px;
      }
      span{
        text-transform: capitalize;
        font-size: 18px;
        font-weight: bold;
        font-family: Montserrat-Bold;

      }
    }
    .title-program{
      font-family: Montserrat-Bold;
      text-transform: uppercase;
      color:$red;
      font-weight:bold;

    }
  }

  .toggle-detail-persons{
    cursor:pointer;
    position:absolute;
    bottom: -27px;
    width:100%;
    text-align: center;
    span{
      background-color:$grey;
      color:white;
      padding: 5px 40px 5px 40px;
      border-radius: 0 0 3.125rem 3.125rem;
      border-top-width: 0;
      z-index: -1;
    }
  }

}

.container-user-error{
  .text-error{
    color:$red;
    display:block;
    font-weight:bold;
    font-size:14px;
  }
  input{
    border-color:$red;
  }
}
