.page-admin{
  .container-nav{
    background-color: black;
    header{
      color:white;
      text-align:center;
    }
    .nav-admin{
      margin-top:20px;
      margin-bottom: 40px;
      li{
        background-color: lighten(black,20);
        margin-bottom:2px;
        padding: 10px;
        &:hover{
          background-color: white;
          cursor: pointer;
          a{
            color:black;
            i{
              color:black;
            }
          }

        }
        a{
          color:white;
          i{
            color:white;
          }
        }

      }
    }
  }

}