@font-face {
  font-family: 'Montserrat-Regular';
  src: url('~/public/assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('~/public/assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('~/public/assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'KGNoRegretsSketch';
  src: url('~/public/assets/fonts/KGNoRegretsSketch/KGNoRegretsSketch.ttf');
}

@font-face {
  font-family: 'SketchCollege';
  src: url('~/public/assets/fonts/SketchCollege/SketchCollege.ttf');
}

@font-face {
  font-family: 'Bebas';
  src: url('~/public/assets/fonts/Bebas/BebasNeue-Regular.ttf');
}