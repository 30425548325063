/********* Banners Home ************/


@media screen and (max-width: 426px) {
  #rubina-testimony{
    padding-top:15px;
    img{
      width:100%;
    }
  }
  #program-top-top{
    background-repeat: no-repeat;background-image: url('~/public/img/program/bg-program2.jpg')!important;
  }
  #program-express-summer{
    background-repeat: no-repeat;background-image: url('~/public/img/program/bg-program3.jpg')!important;
  }
  .banner-program#program-top-top,.banner-program#program-express-summer{

    background-size: cover;
    background-position: center;
    min-height:646px!important;
    padding-top: 60px;
    button,a,h2{
      display:none;
    }
    h2{

    }
  }
  .banner-home h2{
    font-size: 1.3em !important;
    margin-top: 141px;
    background-color: black;
    padding: 7px;
  }
.fitgroups-body{
  .container-content{
    padding-right:0px!important;
    padding-left:0px!important;
  }
}
  .creditcard{
    width:100%!important;
    .ccfront{
      display: block;
      width: 100%!important;
    }
  }

  .instagram-body .block-event-soon{
    padding:0!important;
  }
  .banner-home {
    //background-position: -121px -168px;
    background-image: url('~/public/img/home/mbavis3.jpg');
    background-size: contain;
    min-height: 307px;
    .card-item{
      background-color:$grey;
    }
  }
  .mobile-bg{
    background-image: url('~/public/img/home/fitnessavoda-video-exercice.jpg');
    background-size: cover;
    background-position:center center;
    padding-top: 40px;
    padding-bottom: 30px;
    background-repeat: no-repeat;
  }
  .fitgroup-home{
    padding: 65px 10px 10px 10px!important;
  }
  .aboutus-body{
    .fitgroup-home{
      padding: 100px 10px 10px 10px!important;
    }
  }
  #banner-video-mobile{
    display:block;
    i{
      position:absolute;
      color:$red;
      font-size:60px;
      left: 42%;
      right: 50%;
      bottom: 50%;
      top: 39%;
      &:hover{
        color:white;
      }
    }
    ul{
      li{
        margin-bottom:5px;
      }
    }
  }
  .banner-video{
    display:none;
  }
  .why-us-home h3{
    padding:20px!important;
  }
}

@media screen and (min-width: 427px) {

  #rubina-testimony{
    img{
      width:75%;
    }
  }

  #banner-video-mobile{
    display:none;
  }
  .banner-video{
    display:block;
  }

  .banner-home {
    background-size: cover;
    background-position:center center;
    min-height: 475px;
    background-image: url('~/public/img/home/home-2.png');
  }
  .presentation-video-exrcices{
    background-image: url('~/public/img/home/bg-video-exercices.png');
  }
  .before-home .user-text #notice-user::after {
    position: absolute;
    content: "";
    white-space: pre-wrap;
    color:white;
    bottom: 69px;
    right: -43px;
    width: 124px;
    height: 128px;;
    background: url('~/public/img/home/stars.png') center no-repeat;
    font-weight: bold;
    z-index: 1;
    padding-top: 50px;
    padding-left: 28px;
  }
}
@media screen and (max-width: 968px){
  .nav{
    padding-top: 7px!important;
    padding-bottom: 7px!important;
  }
}
