body{
  font-family: Montserrat-Regular;
  .logo-text{
    font-size: 25px;
    font-family: Bebas!important;
    .fit{
      color:#F4323D;
    }
    .nessavoda{
      color: #BC1B81;
    }
  }
  .dropdown-toggle{
    font-size: 18px;
    margin-top: 7px;
  }
  .dropdown-menu[data-bs-popper]{
    right:0px;
  }
}
@media screen and (min-width: 992px){
  #main-header{
    .navbar-expand-lg .navbar-collapse {
      display:block!important;
    }
  }
}

@media screen and (max-width: 426px) {
  .navbar > .container{
    flex-direction: row-reverse !important;
  }
  .mobile-menu{
    display:flex!important;
  }
  .desktop-menu{
    display:none!important;
  }
}

.mobile-menu{
  display:none;
}

.desktop-menu{
  display:flex;
}

#main-header{
  color:#5D3C81;
  .nav-link{
    color:#5D3C81;
  }
  .dropdown{
    span,a{
      color:#5D3C81;
    }
  }

}
.nav{
  background-color:black;// $background-nav!important;
  color: white;
  padding-bottom: 0!important;
  padding-top: 0!important;
  padding-left: 15px;
  .dropdown-menu .dropdown-item{
    &.active{
      background-color: $red!important;
    }
    &:hover{
      background-color: $red!important;
    }
  }
  .navbar-toggler{
    background-color: $red!important;
    color: white;
  }
  ul{
    li{
      &.nav-item{
        &:hover,&.active{
          background-color: $red;
        }
        a{
          padding: 10px;
          color: $white!important;
          font-family: Montserrat-Bold;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
  }
  .dropdown-menu{
    background-color: $background-nav!important;
    border: none!important;
    a{
      font-size: 15px!important;
      color: white;
      text-transform: capitalize!important;
      &+a{
        border-top:1px solid white;
      }
    }
  }
  .instagram{
    background-color:$red;
    color: white !important;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    font-size: 18px;
  }
}
.dropdown-menu{
  border: none;
  .dropdown-item{
    &:hover{
      background-color: #E99F82;
    }
  }
}
footer{
  background-color: white;
  padding: 20px;
  color:black;
  a{
    color:inherit;
    &:hover{
      color:inherit;
    }
  }
}
#form-contact{
  select{
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  input[type=text],textarea{
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
}


.app-msg{
  padding: 30px 41px 10px 32px!important;
  &.danger{
    .header-success{
      display:none;
    }
  }

  &.warning{
    .alert-warning{
      background-color:white!important;
      color:$red!important;
    }
    border:2px solid $red;
    .header-success{
      display:none;
    }
  }

  &.success{
    .header-success{
      color:#155724;
      font-weight:bold;
      font-size:20px;
      margin-bottom:10px;
      i{
        margin-right:10px;
        font-size:25px;
        color:#155724;
      }
    }
    .alert-success{
      background-color:white!important;
    }
    border:2px solid #155724;
  }
}

