.generic-header{

  &.fitgroups-header{
    background: url('~/public/img/fitgroups/bg-fitgroups-2.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 70px;
    h1{
      color: white;
      font-family:'Montserrat-Bold';
      text-transform: uppercase;
    }
  }
}
.fitgroups-body{
  padding-bottom: 74px;
  padding-top: 40px;
  .cadre{
    text-align: center;
    span{
      font-weight:bold;
    }
    .portrait{
      height: 100px;
      width: 140px;
      border: 4px solid transparent;
      margin-bottom: 10px;
      display: block;
      &.audrey{
        border-color:#3e7a36;
      }
      &.mathieu{
        border-color:#6785ba;
      }
      &.capucine{
        border-color:#a57c55;
      }
    }
  }
  .hearder-fitgroup-type{
     background: url('~/public/img/fitgroups/bg-water.jpg');
    background-repeat: repeat-x;
    height:200px;
    width:100%;
    background-color:red;
    h2{
      color:white;
      font-family:SketchCollege;
      text-align:center;
      font-size:40px;
    }
    .accroche{
      color:white;
      display:block;
      font-family:Roboto-Bold;
      font-size:20px;
    }
  }
  button,input[type="submit"]{
    padding-right: 60px;
    padding-left: 60px;
    text-transform: uppercase;
    font-family: Roboto-Bold;

  }

  input[type=text]{
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
}

.desc-fitgroup{
  p{
    font-family: Roboto;
    span{
      &.red{
        color: $red;
      }
      &.strong{
        font-family: Roboto-Bold;
      }
      &.accroche-size{
        font-size:28px;
      }
    }
  }
  #list-accroche{
    span{
      display:block;
      background-color:grey;
      color:white;
      &.green{
        background-color:#3F9733;
      }
      &.orange{
        background-color:#BB7B1F;
      }
      &.gray{
        background-color:#3D3D3B;
      }
    }
    img{
      cursor:pointer;
    }
  }
  .title-accroche{
    font-size:23px;
  }
}

.fitgroups-group{

    span{
      color:$red;
      font-family: Roboto-Bold;
    }
    .brands-fit{
      font-family: Montserrat-Bold;
      color: $red;
    }
    .brands-group-name{
      font-family: Montserrat-Bold;
      color: black;
    }

}
#ajax-return-text{
  display:none;
}