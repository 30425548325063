
#header-page-video{
  background: rgb(96,62,126);
  background: linear-gradient(138deg, rgba(96,62,126,1) 0%, rgba(232,54,70,1) 100%);
}

#list-video{
  a{
    color:black;
    text-decoration: none;
  }
}
@media screen and (max-width: 426px) {
  #header-page-video{
      img{
        margin-bottom: -92px;
        margin-top: -40px;
        width: 100%!important;
      }
      h1{
        text-align: center;
      }
    }
  #list-video{
    img{
      width: 100%!important;
      height: 100%!important;
    }
    a{
      width: 70%!important;
      margin: 0 auto;
      display:inline-block;
    }
  }

}