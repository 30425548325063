
/*=============================================
=             Colours                         =
=============================================*/

$grey:            #4a4a4a;
$red:             #cc3333;
$white:           white;
$blue:            #0683bf;

$purple: #5D3C81;
$blue-light:#03C1DE;
$brown-light:#E99F82;
$yellow-light:#FED183;
$pink:#BC1B81;

/************ layout ***********************/

$background-nav:  $grey;