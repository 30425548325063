.generic-header{
  &.offers-header{
    text-align: center;
    padding-top: 70px;
    h1{
      color: white;
      font-family:'Montserrat-Bold';
      text-transform: uppercase;
    }
  }
}

.offers-body{
  padding-bottom: 74px;
  padding-top: 40px;
  h3{
    font-family: Roboto;
    span{
      color: $red;
      font-weight: bold;
    }
  }

  .container-offers{
    position: relative;
    margin-top: 63px;
    &::before{
      position: absolute;
      content: '';
      top: -64px;
      left: -43px;
      width: 124px;
      height: 128px;;
      background: url('~/public/img/offers/stars-blue.png') center no-repeat;
      z-index: 1;
    }

    &::after{
      position: absolute;
      content: '';
      bottom: -80px;
      right: -43px;
      width: 124px;
      height: 128px;;
      background: url('~/public/img/offers/stars-blue.png') center no-repeat;
      z-index: 1;
    }


    background-color: #f0f0f0;

    .block-offer{
      padding-bottom: 20px;
      background-color: white;

      .bg-image{
        position: relative;

        .container-promo{
          .block-promo{
            border: 4px solid $red;
            border-radius:100%;
            padding: 10px;
            width: 115px;
            height: 115px;
            .text-promo{
              border: 1px solid $red;
              border-radius: 100%;
              width: 100%;
              height: 87px;
              color: white;
              font-size: 20px;
              line-height: 90px;
              &.line-through{
              text-decoration: line-through;
            }
            }
          }
        }
      }

      h2{
        font-family: Roboto-Bold;
        text-transform: uppercase;
        color: white;
      }
      .container-price{
        margin-bottom: 15px;
        .price{
          display: block;
          font-family: Roboto-Bold;
          font-size: 60px;
          text-align: center;
          color: $red;
        }
        .detail-price{
          margin-top: -19px;
          font-family: Roboto-Bold;
          font-size: 14px;
          display: block;
        }
      }

      button{
        text-transform: uppercase;
        padding-left: 45px;
        padding-right: 45px;
        font-size: 18px;
        font-weight: bold;
      }
    }

    & #double-offers .bg-image{
      background-image: url('~/public/img/offers/new-bg-offer-duo.jpg');width: 100%;height: 300px;
      background-position:left;
    }

    & #simple-offers .bg-image{
      background-image: url('~/public/img/offers/new-bg-offer-solo.jpg');width: 100%;height: 300px;
      background-position:center;
    }
  }
}
.offers-details{
  background-color: #444141;
  color: white;
  font-family: Roboto;
  .title{
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 15px;
    padding-bottom: 2px;
  }
  ul{
    display: block;
    li{
      margin-bottom: 10px;
    }
  }
}