@media screen and (max-width: 426px) {
  #header-page-aboutus{
    img{
      margin-bottom: 17px;
      margin-top: 22px;
      width: 30% !important;
    }
    h1{
      text-align: center;
    }
  }
}

@media screen and (min-width: 427px) {
  #header-page-aboutus{
    img{

      margin: 40px;
      width: 10% !important;
    }
  }
}

#page-aboutus {
  padding-bottom: 74px;
  #header-page-aboutus{
    background: rgb(184,36,129);
    background: linear-gradient(138deg, rgba(184,36,129,1) 0%, rgba(95,62,128,1) 100%);
    img{
      width: 50%;
    }
  }
  .block-actor{
    margin-bottom: 65px;
  }

  .section-actor{

    h2{
      margin-bottom: 20px;
      font-family: Bebas;
      font-size: 34px;
      color:$purple;
    }
    .profil-actor{
      margin-bottom: 20px;
      li{
        &.job{
          font-family: Bebas;
        }
        &.social{
          i{
            margin-right: 38px;
            font-size:16px;
          }
          a{
            color:black;
          }
          font-size:14px;
        }
        &.diplome{
          margin-top:10px;
          font-size:14px;
          i{
            margin-right:15px;
            font-size:16px;
          }
        }
        span.red{
          font-family: Bebas;
          font-size: 16px;
          color: $red;
        }
      }
    }

    .description{
      position: relative;
      text-indent: 4.8em;
      text-align: justify;
      margin-top: 50px;
      &:before,&:after{
        font-size: 60px;
        color: $purple;
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 900;
        position: absolute;
      }
      &:before{
        content: "\f10d";
        margin-right:15px;
        left: -1.3em;
        top: -0.8em;

    }
      &:after{
        content: "\f10e";
        margin-top: 10px;
        right: 0;
      }
    }
  }

  .h2-display-mobile{
    font-family: Bebas;
    font-size: 34px;
  }
  .line-team{
    margin-bottom: 81px;
  }
}