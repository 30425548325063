.btn{
  &.call-action{
    background-color: $blue;
    color: white;
    font-family: Roboto-Bold;
    &:hover{
      color: white;
      background-color: darken($blue,15%);
    }
  }
}
.text-content-popover{
  .line{
    display:block;
    margin-bottom: 5px;
    i{
      color:black;
      width: 25px;
      font-size: 16px;
    }
  }
  a{
    margin: auto;
    display: block;
    color:white;
    background-color:$grey;
    margin-top:10px;
  }
}
.content-popover{
  display: none;

}

.backgroundColor-white{
  background-color: white;
}

.backgroundColor-black{
  background-color: black;
}

.bg-home-color{
  background: rgb(46,186,204);
  background: linear-gradient(139deg, rgba(46,186,204,1) 0%, rgba(233,159,130,1) 55%, rgba(254,209,131,1) 100%);
}

.transition{
  position: absolute;
  font-weight: bold;
  color: white;
  padding: 10px;
  margin-top: -38px;
  text-transform: uppercase;
  text-align: center;
  line-height: 60px;
  z-index: 1;
  border-bottom: 40px solid #4a4a4a;
  border-left  : 25px solid transparent;
  border-right : 25px solid transparent;
  height : 0;
}

.no-padding-lr{
  padding-right: 0!important;
  padding-left: 0!important;
}

.no-margin-lr{
  margin-right: 0!important;
  margin-left: 0!important;
}

.roboto-bold{
  font-family: Roboto-Bold;
}

.clear{
  clear:both;
}

.generic-header{
  height:350px;
  background-color: $grey;
}
.generic-container-body {

  background: linear-gradient(0deg,white 10%,white 11%);
  -webkit-transform: skew(0deg,7.5deg);
  -moz-transform: skew(0deg,7.5deg);
  transform: skew(0deg, 7.5deg) translate(0px,-96px);
  padding-top: 40px;
  height: auto;
  overflow: hidden;
  .generic-body{
    transform: skew(0deg, -7.5deg);
  }
}

.overflow{
  overflow: hidden;
}

.height-auto{
  height:auto;
}

.blockvideo{
  position: relative;
  text-align: center;
  &:hover{
    i{
      color:white;
    }
  }
  i{
    position:absolute;
    color:$red;
    bottom:0;
    top:0;
    margin:auto;
    left:0;
    right:0;
    font-size:50px;
  }
}

.bg-white-2{
  background-color: #FDF8FB;
}
.text-color-pink{
  color: $pink;
}

.text-color-purple{
  color:$purple;
}
.btn-pink{
  background-color: $pink;
  color:white;
  border-radius: 0!important;
}

.btn-purple{
  background-color: $purple;
  color:white;
  border-radius: 0!important;
  &:hover{
    background-color: darken($purple,10);
    color:white;
  }
}
.btn-yellow{
  background-color: $yellow-light;
  color:white;
  border-radius: 0!important;
  &:hover{
    background-color: darken($yellow-light,10);
    color:white;
  }
}

.btn-brown{
  background-color: $brown-light;
  color:white;
  border-radius: 0!important;
  &:hover{
    background-color: darken($brown-light,10);
    color:white;
  }
}
.btn-blue{
  background-color: $blue-light;
  color:white;
  border-radius: 0!important;
  &:hover{
    background-color: darken($blue-light,10);
    color:white;
  }
}
.list-social{


    i{
      font-size: 29px;
      color: white;
      padding: 6px;
      text-align: center;
      border-radius: 100%;
      box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
      width: 41px;
      margin: auto;
      margin-bottom: 15px;

      &.fa-facebook-f{
        background-color:#4267B2 ;
      }
      &.fa-snapchat{
        background-color: #FFFC00;
      }
      &.fa-instagram{
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
      }
    }
}

.family-bebas{
  font-family: Bebas;
  font-weight: 500!important;
}