@media screen and (max-width: 943px){
  .bg-banner-fitgroup {
    height: 100%!important;
    background-image: url("~/public/img/home/banner-2.jpeg")!important;
    padding-bottom: 50px!important;
  }
}
@media screen and (max-width: 426px) {
  .section-home{
    .poster-program{
      width: 100%!important;
    }
  }
  .block-program {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .block-section-fitgroup span:nth-child(3) {
    font-size: 38px!important;
  }

  .section-social-network{
    position:relative;
    .left-block{
      width: 100%!important;

    }
    .right-block{
      position:absolute;
      width: 100%!important;
      top: 40%;
    }
    .hide-mobile{
      display:none;
    }
    .show-mobile{
      display:block!important;
      font-size: 30px;
      margin-bottom: 10px;
    }
  }

  .section-home #block-text {
    padding-top: 0!important;
  }

  .section-home #block-text #text-2 {
    font-size: 20px!important;
    margin-bottom: -15px;
  }
}

.section-social-network{
  .left-block,.right-block{
    width: 50%;
    .show-mobile{
      display:none;
    }
  }
}

.pd-home{
  padding-top: 40px;
  padding-bottom: 40px;
}
.section-home{
  .poster-program{
    width: 30%;
  }
  #block-text{
    padding-top: 80px;
    padding-bottom: 40px;
    h2{
      font-size: 55px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    #text-2{
      font-size: 40px;
      font-weight: 700;
    }
    #text-3{
      color: $purple;
      font-size: 45px;
      font-weight: bold;
      span{
        font-size: 70px;
      }
    }
    a{
      border:2px solid $purple;
      color: $purple;
      border-radius: 0px;
      font-weight: 700;
      font-size: 22px;
    }
  }
}
h2.title{
  color:$purple;
  font-weight: 500;
  margin-bottom: 45px;
  text-transform: uppercase;
  text-align: center;
  font-family: Bebas;
  font-size: 34px;
}
.bg-fit-program{
  background-size: 100%;
  background-repeat: no-repeat;
}

.bg-poster-program{
  height: 290px;
  width: 80%;
  margin: 0 auto;

  .price{
    position: absolute;
    color: white;
    bottom: 5px;
    right: -19px;
    font-weight: 500;
    font-size: 50px;
    font-family: Bebas;
  }
}

.block-program{
  width: 300px;
  border:1px solid rgb(92,61,129);
  border-radius: 10px;
  &.first{
    background: rgb(93,60,129);
    background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(3,193,222,1) 58%);
    .bg-fit-program{
      background-image: url("~/public/img/home/fit2.png");
    }
    .text-block{
      header{
        color:$blue-light;
      }
      a{
        background-color:$blue-light ;
        color: white;
      }
    }

  }
  &.second{
    background: rgb(93,60,129);
    background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(245,148,87,1) 72%);
    .bg-fit-program{
      background-image: url("~/public/img/home/fit2ete.png");
    }
    .text-block{
      header{
        color:$brown-light;
      }
      a{
        background-color:$brown-light;
        color: white;
      }
    }

  }
  &.third{
    background: rgb(93,60,129);
    background: linear-gradient(139deg, rgba(93,60,129,1) 0%, rgba(254,209,130,1) 72%);
    .bg-fit-program{
      background-image: url("~/public/img/home/bikini.png");
      background-position: center;
      h2{
        color:#f23833;
        font-size: 40px;
        padding-top: 40px;
      }
    }
    .text-block{
      header{
        color:$yellow-light;
      }
      a{
        background-color:$yellow-light;
        color: white;
      }
    }

  }
  .text-block{
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0px 0px 9px 9px;
  }
  .text-block{
    header{
      margin-bottom: 10px;
      font-weight: 500;
      font-family: Bebas;

      h4{
        margin-bottom: 0px;
        font-size: 34px;
      }
      div{
        font-size: 30px;
      }
    }
    a{
      text-decoration: none;
      padding: 8px;
      margin-top: 20px;
      display: inline-block;
    }
  }
}

.concept-block{
  background-color: #FDF8FB;
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;
  .line{
    text-align: center;
    i{
      display:block;
      color:$purple;
      font-size: 25px;
      margin-bottom: 15px;
    }
  }
}

.bg-banner-fitgroup{
  background-image: url("~/public/img/home/banner.png");
  width: 100%;
  height: 400px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0 83px;
}
.block-section-fitgroup{
  span{
    font-weight: bold;
  }
  span:nth-child(1){
    font-size: 45px;
    margin-bottom: -31px;
  }
  span:nth-child(2){
    font-size: 65px;
  }
  span:nth-child(3){
    font-size: 65px;
  }
  span:nth-child(4){
    font-size: 65px;
  }
  span:nth-child(5){
    font-size: 30px;
  }
}

.banner-home{
  width: 100%;

  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  text-align:center;

  .card-item{
    padding: 5px;
    margin-top: auto;
    li{
      padding: 5px;
      a{
        display: block;
        background-color: $white;
        text-align: center!important;
        padding: 15px;
        height: 100%;
        i{
          color: $blue;
          font-size: 30px;
        }
        span{
          margin-top: 15px;
          display: block;
          text-transform: uppercase;
          font-family: Roboto-Bold;
          font-weight: bold;
          color: black!important;
        }
        &:hover{
          text-decoration: none;
        }

      }
    }
  }
  h2{
    font-family:'SketchCollege';
    color:white;
    font-size:4em;
  }
  .accroche{
    font-family:'SketchCollege';
    color:white;
    font-size:2em;
  }
}
.home-h1{
  padding-top: 45px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Montserrat-Bold";
  text-align: center;
  width: 100%;
  font-size: 28px;
  span{
    color: $red;
    font-size: 30px;
  }
}
.background-simulator{
  width: 100%;
  padding-bottom: 50px;
  display:none;

  &.open{

  }
  .input-group-text{
    background-color: white;
    color:$purple;
    font-weight: bold;
    border-color:$purple!important;
    border-right:1px solid;
    border-top:1px solid;
    border-bottom:1px solid;
    border-left:0;
  }
  input[type="text"].form-control{
    border-color:$purple!important;
    border-left:1px solid;
    border-top:1px solid;
    border-bottom:1px solid;
    border-right:0px;

  }
  input[type="submit"]{
    background-color: white;
    color:$purple;
    border:1px solid $purple;
    &:hover{
      background-color: $purple;
      color:white;
    }
  }
  h2{
    color: #BC1B83;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;
    font-size: 32px;
  }
  .mesure{
    color:white;
    font-size:18px;
    font-weight:bold;
  }
  .call-simulator,.result-simulator{
    margin:0 auto ;
    width: 100%;
    text-align: center;
  }

  input{
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $purple;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    transition: border-color .15s;
  }
}
.before-home{
  .carousel-indicators{
    position:inherit;
  }
  background-color: #c9c9c9;
  width: 100%;
  .carousel{
    background-color: black;
    img{
     /* margin: 0 auto;
      display: block;*/
    }
    ul{
      li{
        .desc-img{
        .title-program,.name-heavy{
          display: block;
          font-family: Roboto-Bold;
          color: white;
        }
          .title-program{

            font-size: 18px;
          }
          .name-heavy{
            color: white;
            span{

              color: $red;
              font-size: 18px;
              margin-left: 15px;
            }
          }
        }
      }
    }
    margin-bottom: 40px;
    a{
      color: white;
      i{
        color:white;

      }
    }
  }
  .user-text{
    padding-top: 80px;
    padding-bottom: 80px;
    h2{
      font-family: Montserrat-Bold;
      text-transform: uppercase;
    }
    .p-describe{
      font-size: 18px;
      a{
        color:inherit;
      }
    }
    p{
      font-family: Roboto;
      span{
        color: $red;
        font-weight: bold;
      }
    }
    .p-price{
      font-family: Roboto-Bold;
      .label{
        display: inline-block;
        color: black;
        font-size: 25px;
        margin-right: 60px;
      }
      .price{
        font-size: 40px;
        display: inline-block;
        span{
          display: block!important;
          &.normal-price{
            color: black;
            text-decoration: line-through;
            font-size: 20px;
            text-align: right;
          }
        }
      }
    }
    .call-action{

    }
    #notice-user{
      position: relative;
      &::before{
        position: absolute;
        content: '';
        top: -64px;
        left: -70px;
        width: 124px;
        height: 128px;;
        background: url('~/public/img/home/stars.png') center no-repeat;
        z-index: 1;
      }


    }
  }
  &::after{
    @extend .transition;
    /*content: "Sport à la maison";
    left: 0;
    right:0;
    width: 300px;
    margin: -38px auto;*/
    display:none;
  }
}

.simulator-home{
  h1{
    &::after{
      @extend .transition;
      content: "Simulateur";
      left: 0;
      right:0;
      width: 300px;
      margin: 81px auto;
      font-size:14px;
    }
  }
  &::after{
    @extend .transition;
    content: "Témoignages";
    left: 0;
    right:0;
    width: 300px;
    margin: -38px auto;
  }

}
.why-us-home{
  &::after{
    @extend .transition;
    content: "Les FitGroups";
    left: 0;
    right:0;
    width: 300px;
    margin: -38px auto;
  }
  .banner-video{
    min-height: 367px;
    background-image: url('~/public/img/home/presentation-video-3.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-bottom:40px;
    text-align:center;
    padding-top: 135px;
    i{
      display:block;
      color:$red;
      z-index: 2;
      font-size: 69px;
      margin-bottom: 50px;
      cursor:pointer;
      &:hover{
        color:white;
      }
    }
    span{
      display:block;
      color:white;
      font-size: 30px;
      font-weight: bold;
    }
  }
  h3{
    padding:80px;
  }
}

.presentation-video-exrcices{
  width: 100%;
  min-height: 388px;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  background-position: left;
  .link{
    color:$red;
    text-decoration:underline;
  }
.col-md-5{
  display: flex;
  flex-direction: column; /* direction d'affichage verticale */
  justify-content: center;
}
  .text{
    background-color: rgba(0,0,0,0.75);
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 25px;
    padding-right: 25px;
    display: inline-block;
    margin: auto;
    p{
      color: white;
      opacity: 1.0;
      font-family: Roboto-Bold;
    }
    .fa-play-circle{
      font-size:25px;
    }
  }
  .img-multidevices{
    display: block;
    margin: 0 auto;
  }
}

.fitgroup-home{
  padding: 100px 10px 10px 10px;
  background-image: url('~/public/img/home/bg-fitgroup.png');
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-position: bottom center;
  h3{
    text-align: center;
    font-family: Roboto;
    span{
      color: $red;
      font-family: Roboto-Bold;
      font-size: 25px;
      text-transform: uppercase;
    }
  }
  .link-register{
    margin: 0 auto;
    display: block;
    width: 209px;
  }
  h2{
    span{
      color:$red;
      font-family: Roboto-Bold;
    }
    .brands-fit{
      font-family: Montserrat-Bold;
      color: $red;
    }
    .brands-group-name{
      font-family: Montserrat-Bold;
      color: black;
    }
  }

  .list-social{
    li{
      padding: 5px;
      i{
        font-size: 30px;
        color: white;
        padding: 10px;
        text-align: center;
        border-radius: 100%;
        box-shadow: 0px 3px 10px rgba(0,0,0,.25);

        &.fa-facebook-f{
          background-color:#4267B2 ;
        }
        &.fa-snapchat{
          background-color: #FFFC00;
        }
        &.fa-instagram{
          background: #d6249f;
          background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
        }
      }
    }

  }

}

.infos-program{
  padding-top:10px;
  padding-bottom:10px;
  background-color: black;
  li{
    i{
      color:$blue;
      font-size:25px;
      margin-bottom:10px;
    }
    span{
      color:white;
    }
  }
}