.generic-header{

  &.instagram-header{
    background: url('~/public/img/instagram/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    text-align: left;
    padding-top: 70px;
    min-height: 451px;
    background-position: center;
    h1{
      color: $red;
      font-family:'Montserrat-Bold';
      text-transform: uppercase;
      font-weight:bold;
    }
    #accroche{
      padding: 0;
      margin:0;
      li{
        display:block;
        color:white;
        font-size:30px;
        font-family:'Montserrat-Bold';
        font-weight:bold;
        &:first-child::before{
          background-color:$blue;
        }
        &::before{
          height: 32px;
          width: 10px;
          background-color: $grey;
          content: " ";
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
  }
}
.instagram-body {
  padding-bottom: 74px;
  h2,h4{
    font-family: Roboto-Bold;
  }
.block-event-soon{
  background-color:#c9c9c9;
  padding:60px;
  .block-right{
    background-color:white;
    padding:20px;

    ul{
      li{
        i{
          width:20px;
          color:darken($grey,10);
        }
      }
    }
  }
}

  .subscript-instagram{
    padding: 60px;
    .accroche{
      font-family: Roboto-Bold;
      font-size:28px;
      color:black;
      a{
        color:$red;
        font-size:26px;
      }
    }
    .line{
      display:block;
      &.number{
        color:$grey;
        font-size:20px;
      }
    }
  }
  .list-events{
    li{
      a{
        color:black;
        .firstname{

        }
      }
    }
  }
}